<template>
<div>
  <Modal titulo="Artículo" @close="toggleModal" :modalActive="true">
      <div class="row">
           <div class="m-auto mt-3 mb-3" v-if="pais == 'Peru' && false">
             <button class="btn btn-primary b-radius text-uppercase fw-bold" @click="toggleModalImeiOsiptel">Check Imeis</button>
           </div>

               <div class="col-md-12 form-floating  mb-3">
                 <div class="row">

                 <div class="col-md-6 mb-3" v-if="false">
                 <div class="form-floating">
                   <select class="form-select" v-model="articulo.categoria" @change="getSubCategorias()">
                       <option v-for="item in categorias" :key="item.id" :value="item.id">{{item.nombre}}</option>
                   </select>
                   <label for="floatingSelect">Categorias <span class="text-danger fw-bold">[*]</span></label>
                    </div>
                  </div>


                    <div class="col-md-6 mb-3" v-if="false">
                    <div class="form-floating">
                      <select class="form-select" v-model="articulo.subcategoria">
                          <option v-for="item in subCategorias" :key="item.id" :value="item.id">{{item.nombre}}</option>
                      </select>
                      <label for="floatingSelect">Sub Categorias</label>
                    </div>
                    </div>
                 
          
               <div class="col-md-12 mb-3" v-if="resetFieldTypeHead">
                   <div class="form-floating position-relative">
                   <vue3-simple-typeahead
                       class="form-control text-center fw-bold"
                       id="typeahead_articulos_historial"
                       placeholder="Escriba el nombre del artículo"
                       :defaultItem="articulo.nombre"
                       :items="articulos"
                       :minInputLength="1"
                       @onInput="getArticulo"
                       @selectItem="selectArticulo"
                   >
                   </vue3-simple-typeahead>
                   <span v-if="loaderInputArticulos" class="spinner-border spinner-border-sm loader-input-articulos" role="status"></span>
                   <label v-if="false && !article" for="floatingInput3">Tu Articulo <span class="text-danger fw-bold">[*]</span></label>
                   </div> 
                   </div>



                 <div class="col-md-3 mb-3" v-if="false">
                 <div class="form-floating">
                   <select class="form-select" v-model="articulo.divisa" @change="getSimbolDivisa" :disabled="true">
                    <option v-for="item in divisaArticulo" :key="item.id" :value="item.divisa">{{item.divisa}}</option>
                  </select>
                    <label for="floatingSelect">Divisa  <span class="text-danger fw-bold">[*]</span></label>
                   </div>
              </div>


               <div class="col-md-6 mb-3">
                     <div class="form-floating">
                       <input type="number" class="form-control text-center fw-bold" v-model="articulo.cantidad" placeholder="Cantidad" autocomplete="off" min="1" oninput="this.value = Math.abs(this.value)">
                       <label for="floatingInput">Cantidad <span class="text-danger fw-bold">[*]</span></label>
                     </div>
                 </div>
      
      
               <div class="col-md-6 mb-3 m-auto">
                    <div class="form-floating">
                      <input type="number" class="form-control text-center fw-bold" v-model="articulo.costo" :placeholder="'Valor Declarado en ' + articulo.divisa" autocomplete="off" min="1">
                      <label for="floatingInput">Costo Producto <span class="text-danger fw-bold">[*]</span></label>
                    </div>
                 </div>


            <div class="col-md-12 d-flex">
            <div class="form-check fs-xl-2 m-auto">
              <input class="form-check-input" v-model="articulo.bateria" type="checkbox" id="checkBattery">
              <label class="form-check-label" for="checkBattery">
               Contiene Bateria?
              </label>
            </div>
            </div>

      </div>
      
      </div>

      </div>

    <div class="buttons-modal-footer">
          <button @click="coreProduct" type="button" class="btn-modal-crear w-90 b-radius">{{!article ? 'Agregar' : 'Actualizar'}}</button>
     </div>
    </Modal>

    <ModalImeiOsiptel @toggleModal="toggleModalImeiOsiptel()" v-if="modalActiveImeiOsiptel" />

    </div>
</template>

<script setup>
import axios from 'axios'
import ModalImeiOsiptel from '@/components/modals/imei-osiptel/Consulta-Imei.vue'
import {getArticulos, dataDivisaByPais} from '@/helpers'
import {reactive, ref, onMounted} from 'vue'
import {useLoading} from 'vue-loading-overlay'
const $loading = useLoading();
import { useToast } from "vue-toastification";
const toast = useToast();
const resetFieldTypeHead = ref(true)
const loaderInputArticulos = ref(false)
const modulo_categorias = ref('categorias')
const modulo_subcategorias = ref('sub-categorias')
const props = defineProps({
  pais: String,
  article: null
})

const emit = defineEmits(['toggleModal'])
const articulos = ref([])
const divisaArticulo = ref([])
const categorias = ref([])
const subCategorias = ref([])

const articulo = reactive({
id: props.article ? props.article.id : '',
nombre: props.article ? props.article.producto : '',  
cantidad: props.article ? props.article.cantidad : '', 
divisa: props.article ? props.article.divisa : 'Dolares',
divisa_simbolo: props.article ? props.article.divisa_simbolo : '$',
costo: props.article ? props.article.costo : '',
categoria: props.article ? props.article.categoria : '',
subcategoria: props.article ? props.article.subcategoria : '',
bateria: props.article ? props.article.bateria : 0,
})


const modalActiveImeiOsiptel = ref(false)

const toggleModalImeiOsiptel = () => {
   modalActiveImeiOsiptel.value = !modalActiveImeiOsiptel.value
}

//Logica store articulos cart 

import { useProductsCartStore } from "../../../store/productsCart";
const productsCart = useProductsCartStore()

const coreProduct = () => {
if(articulo.nombre !== "" && (articulo.cantidad !== "" || articulo.cantidad > 0) 
&& (articulo.costo !== "" && articulo.costo > 0) && articulo.bateria !== "" && articulo.divisa !== "" && articulo.divisa_simbolo !== ""){

if(props.article){
  productsCart.updateProduct(articulo)
}else{
  productsCart.addProduct(articulo)
  resetArticulos()
}

}else{
  toast.info('Complete toda la información del artículo', 3000)
}
}


const subProducto = () => {
productsCart.disminuirArticulos(articulo.nombre)
}

const cantidadArticulo = ref(0)


const addProuctoCantidad = () => {
    productsCart.aumentarArticuloByCantidad(articulo.nombre, cantidadArticulo)
}

/******************** */


const getCategorias = async () => {
  let response = (await axios.get(`${modulo_categorias.value}`)).data
  categorias.value = response
}


const getSubCategorias = async () => {
  let response = (await axios.get(`${modulo_subcategorias.value}/bycategoria/${articulo.categoria}`)).data

  if(response.status == 'error'){
    subCategorias.value = [] 
  }else{
    subCategorias.value = response
  }
  

}


const getArticulo = async (e) => {
    loaderInputArticulos.value = true
    var search
    let jsonArticulos = []
    if(articulo.subcategoria){
    search = articulo.categoria + "/" + articulo.subcategoria + "/" + e.input
    }else{
    //search = articulo.categoria + "/" + e.input
    search = e.input
    }
    const dataArticulos= await getArticulos(search) 
    dataArticulos.map(function(item) {
        jsonArticulos.push(item.articulo)
    })
    articulos.value = jsonArticulos
    loaderInputArticulos.value = false 
    articulo.nombre = e.input
}


const selectArticulo = (e) => {
    articulo.nombre = e
}

const getSimbolDivisa = () => {
let divisaFilter = dataDivisaByPais(props.pais)
let simbolFilter = divisaFilter.divisas.filter(item => item.divisa == articulo.divisa)[0]
articulo.divisa_simbolo = simbolFilter.simbolo
}

onMounted(async () => {
  productsCart.pais = props.pais
  let divisaFilter = dataDivisaByPais(props.pais)
  divisaArticulo.value = divisaFilter.divisas
  await getCategorias()
})

const toggleModal = () => {
    emit('toggleModal', false)
};

const resetArticulos = () => {
articulo.nombre = ''
articulo.cantidad  = '' 
articulo.divisa = 'Dolares'
articulo.divisa_simbolo = '$' 
articulo.costo = ''
articulo.categoria  = ''
articulo.subcategoria  = ''
articulo.bateria  = 0
resetFieldTypeHead.value = !resetFieldTypeHead.value
setTimeout(() => {
  resetFieldTypeHead.value = !resetFieldTypeHead.value
}, 50);
}
</script>