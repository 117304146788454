<template>
<div>

<div class="card">
  <div class="card-body">

<div class="d-flex">

<div class="header-btn-actions">
 <h5 class="text-uppercase text-start fs-bold">Oficinas ({{officesData.length}})</h5>


<div class="d-flex" v-if="actions">
<button
  v-if="!loading"
  title="Ingresar nuevo Registro"
  @click="nuevoRegistro"
  class="btn btn-primary btn-header-tabla ms-2"
>
  <i class="fas fa-plus-square"></i>
</button>

<button
  title="Refrescar Data"
  @click="getOfficesCouriers"
  class="btn btn-primary btn-header-tabla btn-refresh-offices-courier ms-2"
>
  <i class="fa-solid fa-arrows-rotate"></i>
</button>
</div>
</div>    

  <i class="fas fa-building icon-consolidado" aria-hidden="true"></i>
</div>



 


  <div class="form-floating col-12 mt-5 mb-3">
  <input id="inputSearch" v-model="searchOffice" type="search" 
  class="form-control f-bold text-uppercase"
  placeholder="Escriba su coincidencia de búsqueda" autocomplete="off">
   <label for="inputSearch">FIltro de búsqueda</label>
  </div>



    <div class="scroll-card-big" style="height: 345px">
        <div class="animate__animated animate__fadeIn centrar" v-if="loading">
            <h3 class="text-uppercase">Cargando Oficinas 
              <span class="spinner-border me-2 spinner-border-sm" role="status"></span>
            </h3>
        </div>

        <div class="animate__animated animate__fadeIn centrar" v-if="officesData.length === 0 && !loading">
            <h3 class="text-uppercase">{{ courierSelecionado ? `${courierSelecionado.courier ?? ''} sin oficinas` : 'Seleccione courier.'}}<h3 v-if="searchOffice">En {{searchOffice}}</h3></h3>
        </div>



        <div :id="'courierOffice' + item.id" class="card card-oficina-courier w-100 mb-3 animate__animated animate__fadeIn" :class="item.id == selectOficina.id ? 'active' : ''" v-else v-for="item in officesData" :key="item.id"
        @click="selectOficinaCourier(item)"
        >
        <div class="card-body">

        <div class="row justify-content-center">

            <div class="col-md-8">
            <div class="d-flex-flex-column m-2">
            <h6 class="text-uppercase f-bold pe-none">
              {{item.courier.nombre}}
              <small v-if="item.cod_oficina">({{item.cod_oficina}})</small>
              {{!item.estado || item.estado !== 'null' ? ' - ' + item.estado : ''}}
            </h6>

              <p class="text-center">
                {{item.direccion}} <br><b>({{item.ciudad.toUpperCase()}})</b>
                </p>
            </div>
            </div>

            <div class="col-md-4 content-courier-actions">
           <div class="d-flex flex-column">
            <img 
            class="logo-courier m-auto"
            :src="item.courier.logo"
            @error="errorImg"
            alt="">

             <div class="d-flex justify-content-center btn-actions" v-if="actions">
              <button 
               title="Editar"
               @click="Editar(item)"
               class="btn btn-info b-radius text-white ms-1">
                 <i class="fas fa-edit"></i>
              </button>

               <button 
               v-if="$auth.user.rol === 'master'"
               title="Eliminar"
               @click="Eliminar(item.id)"
               class="btn btn-danger b-radius ms-1">
                 <i class="fas fa-trash-alt"></i>
              </button>
             </div>
           </div>
            </div>


          </div>
          </div>
        </div>

      </div>


  


    </div>
  </div> <!-- Fin Template Offices -->


<Modal :titulo="nombreModal" @close="toggleModal" :modalActive="modalActive" v-if="actions">
    <div class="h-agml-scroll">
              
         <div class="row">

         <div class="col-md-6">
            <div class="form-floating mb-3">
               <input v-model="courierOffice.id" type="text" class="form-control" id="inputIdOficina" placeholder="Id Oficina" autocomplete="off" disabled>
               <label for="inputIdOficina">Id</label>
              </div>
            </div>

            <div class="col-md-6">
            <div class="form-floating mb-3">
               <input v-model="courierOffice.cod_oficina" type="text" class="form-control" id="inputCodOficina" placeholder="Codigo Oficina" autocomplete="off">
               <label for="floatingInput2">Cod Oficina <span class="text-danger fw-bold">[*]</span></label>
              </div>
            </div>

        

            <SelectPaisesData v-if="reloadPaises" :data="courierOffice" @paises_select="setDataPais" />
          
        

              <div class="col-md-12">
               <div class="form-floating mb-3">
               <input v-model="courierOffice.direccion" type="text" class="form-control" id="inputDireccion" placeholder="Direccion" autocomplete="off">
               <label for="floatingInput4">Direccion <span class="text-danger fw-bold">[*]</span></label>
               </div>
             </div>


             <div class="col-md-4 mt-3">
             <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" :checked="courierOffice.franquicia" v-model="courierOffice.franquicia">
            <label class="form-check-label text-capitalize" for="flexSwitchCheckDefault1">Franquicia?</label>
            </div>
             </div>

            <div class="col-md-4 mt-3">
             <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault2" :checked="courierOffice.estatus" v-model="courierOffice.estatus">
            <label class="form-check-label text-capitalize" for="flexSwitchCheckDefault2">Oficina Activa? <span class="text-danger fw-bold">*</span></label>
            </div>
             </div>

             
            <div class="col-md-4 mt-3 mb-3">
             <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault3" :checked="courierOffice.entrega_pp" v-model="courierOffice.entrega_pp">
            <label class="form-check-label text-capitalize" for="flexSwitchCheckDefault3">puerta a puerta?</label>
            </div>
             </div>
       
       

             <div class="col-md-6">
               <div class="form-floating mb-3">
               <input v-model="courierOffice.razon_social" type="text" class="form-control" id="floatingInput4" placeholder="Razon Social" autocomplete="off">
               <label for="floatingInput4">Razon Social</label>
               </div>
             </div>


              <div class="col-md-6">
               <div class="form-floating mb-3">
               <input v-model="courierOffice.rif" type="text" class="form-control" id="floatingInput4" placeholder="Rif" autocomplete="off">
               <label for="floatingInput4">Rif</label>
               </div>
             </div>


             
              <div class="col-md-6">
               <div class="form-floating mb-3">
               <input v-model="courierOffice.contacto" type="text" class="form-control" id="floatingInput4" placeholder="Contacto" autocomplete="off">
               <label for="floatingInput4">Contacto</label>
               </div>
             </div>




              <div class="col-md-6">
               <div class="form-floating mb-3">
               <input v-model="courierOffice.correo" type="text" class="form-control" id="floatingInput4" placeholder="Correo" autocomplete="off">
               <label for="floatingInput4">Correo</label>
               </div>
             </div>


             
              <div class="col-md-12">
               <div class="form-floating mb-3">
               <input v-model="courierOffice.telefonos" type="text" class="form-control text-center" id="floatingInput4" placeholder="Telefonos" autocomplete="off">
               <label for="floatingInput4">Telefonos</label>
               </div>
             </div>


             
              <div class="col-md-12">
               <div class="form-floating mb-3">
               <input v-model="courierOffice.horario_atencion" type="text" class="form-control text-center" id="floatingInput4" placeholder="Horarios Atencion" autocomplete="off">
               <label for="floatingInput4">Horarios Atencion</label>
               </div>
             </div>



              <div class="col-md-12">
               <div class="form-floating mb-3">
               <input v-model="courierOffice.observacion" type="text" class="form-control text-center" id="floatingInput4" placeholder="Observaciones" autocomplete="off">
               <label for="floatingInput4">Observaciones</label>
               </div>
             </div>




            </div>

              </div>
 

            <div class="buttons-modal-footer">
            <button v-if="modoModal==0" @click="Crear" type="button" class="btn-modal-crear w-90 b-radius">Crear</button>
            <button v-if="modoModal==1" @click="actualizarRegistro" type="button" class="btn-modal-crear w-90 b-radius">Actualizar</button>
             </div>
            </Modal>


  <ModalConfirm  @close="toggleModalConfirm" :modalActive="modalActiveConfirm" v-if="actions">
      <h5 class="text-uppercase">¿Estas seguro de eliminar el registro de {{modulo}} ID: {{courierOffice.id}}?</h5>
       <div class="buttons-modal-footer">
          <button @click="toggleModalConfirm" type="button" class="bg-dark text-white">Cancelar</button>
          <button @click="EliminarRegistro" type="button" class="btn-modal-crear bg-danger">Si, Seguro</button>
     </div>
   </ModalConfirm>


</div>
</template>
<script setup>
const props = defineProps({
    select: null,
    courierSelecionado: null,
    actions: Boolean,
})
import {ref, onMounted, computed, reactive, watch} from 'vue'
import axios from 'axios'
import { useToast } from 'vue-toastification';
const toast = useToast();
import {useLoading} from 'vue-loading-overlay'
const $loading = useLoading();
const nombreModal = ref(null)
const modalActive = ref(false);
const modalActiveConfirm = ref(false);
const textFiltrado = ref('Filtrado por')
const filterOfficeBy = ref('estado')
const reloadComponent = ref()

const courierOffice = reactive({
  id: '',
  cod_oficina: '',
  id_courier: props.courierSelecionado ? props.courierSelecionado.id : null,
  pais: '',
  estado: '',
  ciudad: '',
  direccion: '',
  entrega_pp: 0,
  estatus: 0,
  razon_social: '',
  rif: '',
  telefonos: '',
  contacto: '',
  horario_atencion: '',
  correo: '',
  franquicia: 0,
  observacion: ''
})

const modoModal = ref('')
const modulo = ref('courier-oficinas')
const oficinas = ref([]);
const loading = ref(true)
const searchOffice = ref('')
const selectOficina = reactive({id: '', courier: ''})
const emit = defineEmits(['oficinaSelected'])
const reloadPaises = ref(false)

const setDataPais = (e) => {
  courierOffice.pais = e.pais
  courierOffice.estado = e.estado
  courierOffice.ciudad = e.ciudad
}


const errorImg = (e) => {
    e.target.src =
      "/favicon.png";
}



const officesData = computed(() =>{
  loading.value = false

  if(!props.courierSelecionado){
    return []
  }

  if(!props.courierSelecionado.status){
    return []
  }


  if(props.courierSelecionado.id == null){
      return []
  }

  if(!searchOffice.value){
    if(!props.courierSelecionado){
      return []
    }
    return oficinas.value.filter(item => item.id_courier == props.courierSelecionado.id)
  }

  return oficinas.value.filter(
  item => item.id_courier == props.courierSelecionado.id && (
  item.estado.toLowerCase().includes(searchOffice.value.toLowerCase()) ||
  item.ciudad.toLowerCase().includes(searchOffice.value.toLowerCase()) ||
  item.direccion.toLowerCase().includes(searchOffice.value.toLowerCase()) ||
  item.cod_oficina.toLowerCase().includes(searchOffice.value.toLowerCase()
  )
  )
  );
})



const getCourierOfficeById = (item) => {
courierOffice.cod_oficina = item.cod_oficina
courierOffice.pais = item.pais
courierOffice.estado = item.estado
courierOffice.ciudad = item.ciudad
courierOffice.direccion = item.direccion
courierOffice.entrega_pp = item.entrega_pp ? 1 : 0
courierOffice.estatus = item.estatus ? 1 : 0
courierOffice.franquicia = item.franquicia ? 1 : 0
courierOffice.razon_social =item.razon_social
courierOffice.rif = item.rif
courierOffice.telefonos = item.telefonos
courierOffice.contacto = item.contacto
courierOffice.horario_atencion = item.horario_atencion
courierOffice.correo = item.correo
courierOffice.observacion = item.observacion
reloadPaises.value = false
setTimeout(() => {
  reloadPaises.value = true
}, 50);
}


watch(() => props.select, data => {
  if(data){
  selectOficinaCourier(data)
  }
})



onMounted(async () => {
getOfficesCouriers()
if(props.select){
  selectOficinaCourier(props.select)
}
})


const getOfficesCouriers = async () => {
  let response = await axios.get(modulo.value)
  oficinas.value = response.data
}



const toggleModal = () => {
      if(modoModal.value == 0){
        nombreModal.value = 'Creacion De Oficina'
      }else{
         nombreModal.value = 'Actualizacion De Oficina'
      }
      modalActive.value = !modalActive.value;
};


const toggleModalConfirm = () => {
      modalActiveConfirm.value = !modalActiveConfirm.value;
};

const Editar = (item) => {
  modoModal.value = 1
  courierOffice.id = item.id
  getCourierOfficeById(item)
  toggleModal()
}

const Eliminar = (id) => {
  courierOffice.id = id
  toggleModalConfirm()
}


const Crear = async () => {
let loader = $loading.show();
try {
let response = (await axios.post(modulo.value, courierOffice)).data
loader.hide();
if(response.status){
       getOfficesCouriers()
       toggleModal()
       toast.success(response.mensaje)
   }else{
       toast.error(response.mensaje)
      }
} catch (error) {
  loader.hide()
  toast.error("Exeption: " + error)
}
}



const actualizarRegistro = async () => {
  try {
  let loader = $loading.show();
  let response = (await axios.put(`${modulo.value}/${courierOffice.id}`, courierOffice)).data
  loader.hide();
  if(response.status){
         getOfficesCouriers()
         toggleModal()
         toast.success(response.mensaje)
    }else{
        toast.error(response.mensaje)
    }
  } catch (error) {
    loader.hide()
    toast.error("Exeption: " + error)
  }
}


const EliminarRegistro = async () => {
 let loader = $loading.show();
 try {
  let response = (await axios.delete(modulo.value + `/${courierOffice.id}`)).data
 loader.hide();
 if(response.status){
       getOfficesCouriers()
       toggleModalConfirm()
       toast.success(response.mensaje)
    }else{
       toast.error(response.mensaje)
    }
 } catch (error) {
  loader.hide()
  toast.error("Exeption: " + error)
 }
}


const selectOficinaCourier = (e) => {
    if(!e){
      selectOficina.id = null
      selectOficina.courier = null
      return
    }
    selectOficina.id = e.id
    selectOficina.courier = e.courier
    emit('oficinaSelected', e)
    let id = 'courierOffice' + e.id
    const elemento = document.getElementById(id);
    if(!elemento){
      return
    }
    setTimeout(() => {
      elemento.scrollIntoView({
      behavior: 'smooth' 
    });
    }, 100);
}
</script>