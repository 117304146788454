<template>
  <div class="p-relative" v-if="$auth.autenticado">
  <Header @toggleSider="setSider($event)" />
  <Sider :toggleSider="siderToggle" />
  <main class="mb-5">
    <slot />
  </main>
    <Footer />
  </div>

<div v-else class="d-flex align-items-center flex-column mt-4">
   <span class="spinner-border spinner-border-sm p-4 mb-3" role="status"></span>
  <h5 class="mb-0">Validando Configuración....</h5>
</div>
</template>


<script setup>
import Header from "@/components/dashboard/Header.vue";
import Sider from "@/components/dashboard/Sider.vue";
import Footer from "@/components/dashboard/Footer.vue";
import {ref} from 'vue'
const siderToggle = ref(false)

const setSider = (e) => {
siderToggle.value = e.value
}
</script>




<style src="../assets/css/styles-sider.css"></style>
<style src="../assets/css/autenticado-layout.css"></style>

<style lang="scss">
   .buttons-modal-footer{
      position:absolute;
      right: 0;
      bottom: 15px;
      width: 100%;
    button{
      margin: 5px;
      padding: 10px 20px;
      border-radius: 15px;
      border: none;
      font-size: 16px;
      cursor: pointer;
      transition: 0.3s all;
    }
    .btn-modal-crear, .btn-modal-edit{
      background-color: var(--primary-2);
      color: #fff; 
      &:hover{
        background-color: var(--primary-alt);
      }
    }
    .btn-modal-close {
      background-color: crimson;
      color: #fff; 
    }
   }
</style>