<template>
      <Modal class="height-search" :titulo="'Busqueda De Clientes'" @close="toggleModal" :modalActive="true">
         
         <p class="text-uppercase fw-bold m-auto w-100 text-danger mt-5">
             Escriba aplicando segun el filtro necesario y
             seleccione el cliente para obtener su informacion
         </p>

        
        <button v-if="modo==0" @click="filtrarTodos" class="btn btn-primary b-radius w-50 m-auto fw-bold" title="Se filtran ultimos registros"
        style="position:relative; top:2rem;"
        >Filtrar Todos
        </button>



        <button @click="filterDataTabla" 
        class="btn btn-info b-radius m-auto fw-bold mt-3 btn-busqueda d-flex align-items-center text-white"
         title="Busqueda segun filtro"
        style="position:relative; top:2rem;">Hacer Busqueda 
        <i class="fa-solid fa-magnifying-glass ms-2"></i>
        </button>
     

          <div class="row">
            
          <div class="col-md-12">
            <div class="input-group mt-5 mb-3">
            <select class="form-select f-bold text-center" v-model="clienteFilterBy">
                <option value="nombre">Nombre</option>
                <option value="documentos">Documentos</option>
                <option value="telefono">Telefono</option>
                <option value="email">Email</option>
            </select>
            <input
            @keyup="filterDataTabla"
            v-model="searchData"
            type="search" class="form-control f-bold text-uppercase input-search-office-courier"
            :placeholder="`Filtrar por ` + clienteFilterBy" autocomplete="off">
                        </div>
          </div>

    <div class="col-md-12">
        <div class="table-responsive" style="height: 350px; overflow-y: scroll;">
        <table id="tabla" class="table table-hover nowrap w-100">
            <thead class="bg-principal-alt text-white" style="position: sticky; top: 0; z-index: 999999;">
            <tr>
                <th v-for='columna in columnasTabla' :key="columna">
                {{columna}}
                </th>
            </tr>
            </thead>
            <tbody class="position-relative">
            <div v-if="loadingTable" class="d-flex align-items-center centrar mt-4">
                <span class="spinner-border spinner-border-sm" role="status"></span>
                <h5 class="mb-0">Filtrando...</h5>
            </div>
            <tr v-else v-for="item in dataTabla" :key='item.id' @click="clienteSelect(item)" title="Click Para Seleccionar">
                <td>{{item.id}}</td>
                <td><b>{{item.documento}}</b> <br> ({{item.tipo_documento}})</td>
                <td>{{item.nombres}}</td>
                <td>{{item.apellidos}}</td>
                <td>{{item.telefono}}</td>
                <td>{{item.email}}</td>
             </tr>
            </tbody>
        </table>
        </div>
          </div>

    </div>
    </Modal>
</template>


<script setup>
import axios from 'axios'
import {ref, defineEmits} from 'vue'
import Modal from '@/components/modals/Modal.vue'
const props = defineProps({
    modo: null,  
})

const emit = defineEmits(['toggleModalFiltradoClientes','dataClienteFilter'])
const modulo = "clientes"
const loadingTable = ref(false)
const clienteSearchData = ref('')
const searchData = ref('')
const clienteFilterBy = ref('nombre')
const columnasTabla = ref(['ID','DOCUMENTO','NOMBRES','APELLIDOS','TELEFONO','EMAIL']);
const dataTabla = ref([])

const filterDataTabla = async () => {
    if(searchData.value !== ""){ 
    loadingTable.value = !loadingTable.value
    let response = (await axios.get(`${modulo}/search?q=${clienteFilterBy.value}&data=${searchData.value}`)).data
    loadingTable.value = !loadingTable.value
    
    if(response.data.length == 0){
        dataTabla.value = []
    }else{
        dataTabla.value = response.data
    }

    }else{
        loadingTable.value = false
        dataTabla.value = []
    }
}


const toggleModal = () => {
    emit('toggleModalFiltradoClientes', false)
}


const clienteSelect = (data) => {  
let clienteFiltrado = {
    id: data.id,
    documento: data.documento,
    nombres: data.nombres,
    apellidos: data.apellidos,
    telefono: data.telefono,
    email: data.email
}

if(props.modo == 0){
var modulo_search = `${modulo}/search?q=${clienteFilterBy.value}&data=${searchData.value}`    
emit('dataClienteFilter', modulo_search)
}else{
emit('dataClienteFilter', clienteFiltrado)
}
toggleModal()
}


const filtrarTodos = () => {  
emit('dataClienteFilter', modulo)  
toggleModal() 
}
</script>


<style scoped>
.btn-busqueda{
        display: none!important;
    }
@media (max-width: 500px) {
    .btn-busqueda{
       display: flex!important;
    }
}
</style>