<template>
    <div class="form-floating position-relative">
        <div class="animate input-loader" v-if="loading"></div>
        <v-select v-else :disabled="disabled" class="form-control" v-model="selectData" :options="data"
            :get-option-label="(option) => option.nombre" :reduce="data => data" 
            @option:selected="setData">

            <template #option="{nombre}">
             <b>{{ nombre }}</b>
            </template>
            
          <template v-slot:no-options>
          Tipo de guía no encontrada...
          </template>
        </v-select>
        <label for="floatingSelect6">{{ titulo ? titulo : 'Tipo de guía'}} <span v-if="required"
                class="text-danger fw-bold">[*]</span></label>
        <i v-if="reload" class="bi bi-arrow-clockwise icon-refresh" @click="loadData" title="Recargar data"></i>
    </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
const props = defineProps({
    required: null,
    reload: null,
    titulo: null,
    select: null,
    disabled: {type: Boolean, default: false}
})
const loading = ref('')
const data = ref([])
const selectData = ref()
const emit = defineEmits(['selected'])

const setData = () => {
    if(!selectData.value){
        return
    }
    emit('selected', selectData.value)
}


const loadData = async () => {
    loading.value = true
    //let response = (await axios.get(`api...`)).data
    let response = [
        {tipo: 0, nombre: 'Aérea'},
        {tipo: 1, nombre: 'Marítima'}
    ]
    loading.value = false
    data.value = response
    if(props.select == 0 || props.select == 1){
        selectData.value = response.filter(item => item.tipo == props.select)[0]
    }
    setData()
}

onMounted(() => {
    loadData()
})


watch(() => props.select, newData => {
    if(newData){
        selectData.value = data.value.filter(item => item.tipo == newData)[0]
    }
})
</script>

<style scoped>
.icon-refresh {
    cursor: pointer;
    position: absolute;
    top: 9px;
    right: 3rem;
    padding: 1.2rem;
    font-weight: bold;
    color: var(--primary)
}
</style>