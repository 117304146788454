<template>
       <Modal :titulo="'Creacion De Cliente'" @close="toggleModal" :modalActive="true">
          <div class="row">
            
          <div class="col-md-6">
             <div class="form-floating mb-3">
              <select class="form-select" v-model="cliente.tipo_documento">
                <option v-for="item in tiposDocs" :key="item.id" :value="item.tipo">{{item.tipo.toUpperCase()}}</option>
              </select>
              <label for="floatingSelect">Tipo Documento <span class="text-danger fw-bold">[*]</span></label>
            </div>
          </div>


            <div class="col-md-6">
               <div class="form-floating mb-3">
               <input v-model="cliente.documento" type="tel" class="form-control" id="floatingInput1" placeholder="Documento" autocomplete="off">
               <label for="floatingInput1">Documento  <span class="text-danger fw-bold">[*]</span></label>
               </div>
            </div>

              <div class="col-md-6">
              <div class="form-floating mb-3">
               <input v-model="cliente.nombres" type="text" class="form-control" id="floatingInput3" placeholder="Nombres" autocomplete="off">
               <label for="floatingInput3">Nombres  <span class="text-danger fw-bold">[*]</span></label>
               </div>
              </div>


              <div class="col-md-6">
               <div class="form-floating mb-3">
               <input v-model="cliente.apellidos" type="text" class="form-control" id="floatingInput4" placeholder="Apellidos" autocomplete="off">
               <label for="floatingInput4">Apellidos  <span class="text-danger fw-bold">[*]</span></label>
               </div>
              </div>

              <PaisData :data="[]" titulo="Nacionalidad" @paises_select="setDataPaises($event)" />

              <div class="col-md-12">
              <div class="form-floating mb-3">
               <input v-model="cliente.telefono" type="number" class="form-control" id="floatingInput5" placeholder="Telefono" autocomplete="off">
               <label for="floatingInput5">Telefono  <span class="text-danger fw-bold">[*]</span></label>
               </div>
               </div>


              <div class="col-md-12">
              <div class="form-floating mb-3">
               <input v-model="cliente.email" type="email" class="form-control" id="floatingInput5" placeholder="Telefono" autocomplete="off">
               <label for="floatingInput5">Email (Opcional)</label>
               </div>
               </div>

    </div>
 
 
    <div class="buttons-modal-footer">
          <button @click="Crear" type="button" class="btn-modal-crear w-90 b-radius">Crear</button>
     </div>
    </Modal>
</template>

<script setup>
import axios from 'axios'
import PaisData from '@/components/dashboard/Paises.vue'
import {reactive, ref, inject, onMounted} from 'vue'
import {useLoading} from 'vue-loading-overlay'
const $loading = useLoading();
import { useToast } from "vue-toastification";
const toast = useToast();
const modulo = ref('clientes')
const emit = defineEmits(['toggleModalCliente', 'clienteCreado', 'success'])
const tiposDocs = ref([])

const cliente = reactive({
tipo_documento: '',
documento: '',
nombres: '',
apellidos: '',
telefono: '',
email: '',
pais: '',
estado: '',
ciudad: ''
})

const setDataPaises = (e) => {
  cliente.pais = e.pais
  cliente.estado = e.estado
  cliente.ciudad = e.ciudad
}

onMounted(async () => {
  tiposDocs.value = await inject('helpers').tiposDocs
})

const toggleModal = () => {
    emit('toggleModalCliente', false)
};

const Crear = async () => {
let loader = $loading.show();

try {
  let jsonData = {
   tipo_documento: cliente.tipo_documento,
   documento: cliente.documento,
   nombres: cliente.nombres,
   apellidos: cliente.apellidos,
   telefono: cliente.telefono,
   email: cliente.email,
   pais: cliente.pais,
   estado: cliente.estado,
   ciudad: cliente.ciudad
  }
  let response = (await axios.post(modulo.value, jsonData)).data
  loader.hide();
  if(response.status){
      emit('clienteCreado', response.data)
      toggleModal()
      toast.success(response.mensaje)
  }else{
    toast.error(response.mensaje)
  }
} catch (error) {
  loader.hide()
  toast.error("Exeption: " + error)
}

}
</script>