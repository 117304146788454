<template>
    <div>
      <div class="form-floating position-relative">
       <vue3-simple-typeahead
       v-if="reloadTypehead"
       class="form-control text-center"
       id="typeahead_destinatario"
       :items="data"
       :itemProjection="item => item.nombres + ' ' + item.apellidos"
       :minInputLength="1"
       :defaultItem="cliente"
       @onInput="getData"
       @selectItem="$emit('selected', $event)">
      </vue3-simple-typeahead>
      <div class="d-flex loader-input-document">
       <span v-if="loaderInputDocumento" class="spinner-border spinner-border-sm c-wait" role="status"></span>
      </div>
     
      <label for="floatingInput3" :class="required ? 'field-required' : ''">{{title ? title : 'Destinatario'}}</label>
     </div> 
   </div>
   </template>
   
   <script setup>
   import {ref, watch} from 'vue'
   import {getDestinatarios} from '@/helpers'
   const reloadTypehead = ref(true)
   
   const props = defineProps({
       title: '',
       cliente: '',
       required: '',
       search: Boolean
   })
   
   const emit = defineEmits(['selected'])
   const loaderInputDocumento = ref(false)
   const data = ref([])
   
   
   const getData = async (e) => {
       loaderInputDocumento.value = true
       const response = await getDestinatarios(e.input) 
       loaderInputDocumento.value = false 
       if(response.data.length == 0){
        data.value = []
           emit('selected', {id: null, documento: e.input})
       }else{
        data.value = response.data
       }
   }
   
   
   const reloadInput = () => {
       reloadTypehead.value = false
       setTimeout(() => {
       reloadTypehead.value = true
      }, 50);
   }
   
   
   
   watch(() => props.cliente, newVal => {
       if (newVal) {
           reloadInput();
       }
   });
   </script>
   
   <style scoped>
   .loader-input-document{
       position:absolute; 
       top: 0.8rem; 
       right: 1rem; 
       padding: 0.6rem;
   }
   
   .c-wait{
       cursor: wait;
   }
   </style>