<template>
    <div class="form-floating">
        <flatPickr class="form-select" v-model="dataSelected" :config="configFlatPickr" 
        @on-change="setData" />
        <label for="floatingSelectFecha">{{ titulo? titulo: 'Fecha' }} <span v-if="required"
                class="text-danger fw-bold">[*]</span></label>
    </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { Spanish } from 'flatpickr/dist/l10n/es'
const dataSelected = ref(null)
const sizeWindow = window.innerWidth
const props = defineProps({
    titulo: null,
    required: null,
    select: null,
    min_date: null,
    months: null
})
const emit = defineEmits(["selected"])
const configFlatPickr = ref({
    wrap: false,
    minDate: props.min_date ? props.min_date : 'today',
    altFormat: 'l j M',
    altInput: true,
    showMonths: computed(() => sizeWindow < 798 ? 1 : !props.months ? 2 : props.months),
    dateFormat: 'Y-m-d',
    defaultDate: 'today',
    locale: Spanish,
    disableMobile: true
})

const setData = () => {
    emit("selected", dataSelected.value)
}

onMounted(() => {
    let date = new Date()
    date.setHours(0,0,0,0)
    let dtoday = date.toJSON().split('T')[0]
    if (props.select) {
        if(props.select == 'empty'){
            return dataSelected.value = ""
        }
        dataSelected.value = props.select
    }else{
        dataSelected.value = dtoday
    }
    setData()
})
</script>