<template>
<div class="row">

  <div class="col-md-4 mb-2">
    <SelectPaises :titulo="titulo ? titulo : 'País'" :reload="reloadComponent" :pais_select="dataPais.pais" @onSetPais="setPais($event)"/>
 </div>


<div class="col-md-4 mb-2" v-if="resetFieldTypeHead">
              <div class="form-floating" >
              <vue3-simple-typeahead
                class="form-control"
                id="typeahead_estados"
                placeholder="Estado / Provincia"
                :items="estados"
                :defaultItem="dataPais.estado"
                :minInputLength="1"
                @selectItem="selectEstado"
                @onBlur="setEstado"
               >
              </vue3-simple-typeahead>
              <label for="floatingInput3" v-if="false">Estado / Provincia <span class="text-danger fw-bold" style="font-size: 10px;">*</span></label>
            </div> 
</div>


                           
<div class="col-md-4 mb-2" v-if="resetFieldTypeHead">
              <div class="form-floating" >
              <vue3-simple-typeahead
                class="form-control"
                id="typeahead_ciudades"
                placeholder="Ciudad / Distrito"
                :items="ciudades"
                :defaultItem="dataPais.ciudad"
                :minInputLength="1"
                @selectItem="selectCiudad"
                @onBlur="setCiudad"
               >
              </vue3-simple-typeahead>
              <label for="floatingInput3" v-if="false">Ciudad / Distrito <span class="text-danger fw-bold" style="font-size: 10px;">*</span></label>
            </div> 
 </div>
          

    </div>
</template>

<script setup>
import {ref, reactive, onMounted, watch} from 'vue'
import {getEstados, getCiudades} from '@/helpers'
import SelectPaises from '@/components/dashboard/selectPaises.vue'
const props = defineProps({
    titulo: null,
    data: {type: Object, default: []} ,
    reload: null
})
const resetFieldTypeHead = ref(true)
const estados = ref([])
const ciudades = ref([])
const dataPais = reactive({
    pais: props.data.pais ? props.data.pais : '',
    estado: props.data.estado ? props.data.estado : '',
    ciudad: props.data.ciudad ? props.data.ciudad : ''
})
const emit = defineEmits(['paises_select'])
const reloadComponent = ref(null)


onMounted(async () => {
  emit('paises_select', dataPais)
})

/**** Funcionalidad paises/estados/ciudades */
const setPais = (e) => {
  dataPais.pais = e.pais
  obtenerEstados()
}


const setEstado = (e) => {
   dataPais.estado = e.input
   emit('paises_select', dataPais)
}

const setCiudad = (e) => {
  dataPais.ciudad = e.input
  emit('paises_select', dataPais)
}

const selectEstado = async (e) => {
  await obtenerCiudades(e)
  document.querySelector("#typeahead_ciudades").focus()
  dataPais.estado = e
  emit('paises_select', dataPais)
}

const selectCiudad = (e) => {
  dataPais.ciudad = e
  emit('paises_select', dataPais)
}

const obtenerEstados = async () => {
  if(!dataPais.pais){
    return
  }
  const data = await getEstados(dataPais.pais)
  estados.value = data.array
  document.querySelector("#typeahead_estados").focus()
  emit('paises_select', dataPais)
}
  
const obtenerCiudades = async (estado) => {
  if(!dataPais.pais){
    return
  }
  const data = await getCiudades(estado)
  ciudades.value = data.array
  emit('paises_select', dataPais)
}
/*************************/

watch(() => props.reload, data => {
  if(data){
    reloadComponent.value = Date.now()
  }
})
</script>