<template>
       <Modal :titulo="titulo" @close="toggleModal" :modalActive="true">
         <div class="h-scroll">
          <div class="row">
            
          <div class="col-md-6">
             <div class="form-floating mb-3">
              <select class="form-select" v-model="clienteDestinatario.tipo_documento">
                <option v-for="item in tiposDocs" :key="item.id" :value="item.tipo">{{item.tipo.toUpperCase()}}</option>
              </select>
              <label for="floatingSelect">Tipo Documento <span class="text-danger fw-bold">[*]</span></label>
            </div>
          </div>


            <div class="col-md-6">
               <div class="form-floating mb-3">
               <input v-model="clienteDestinatario.documento" type="tel" class="form-control" id="floatingInput1" placeholder="Documento" autocomplete="off">
               <label for="floatingInput1">Documento  <span class="text-danger fw-bold">[*]</span></label>
               </div>
            </div>

              <div class="col-md-6">
              <div class="form-floating mb-3">
               <input v-model="clienteDestinatario.nombres" type="text" class="form-control" id="floatingInput3" placeholder="Nombres" autocomplete="off">
               <label for="floatingInput3">Nombres  <span class="text-danger fw-bold">[*]</span></label>
               </div>
              </div>


              <div class="col-md-6">
               <div class="form-floating mb-3">
               <input v-model="clienteDestinatario.apellidos" type="text" class="form-control" id="floatingInput4" placeholder="Apellidos" autocomplete="off">
               <label for="floatingInput4">Apellidos  <span class="text-danger fw-bold">[*]</span></label>
               </div>
              </div>

              <div class="col-md-12">
              <div class="form-floating mb-3">
               <input v-model="clienteDestinatario.telefono" type="number" class="form-control" id="floatingInput5" placeholder="Telefono" autocomplete="off">
               <label for="floatingInput5">Telefono  <span class="text-danger fw-bold">[*]</span></label>
               </div>
               </div>

           <div class="col-md-4 mb-3">
            <div class="form-floating">
              <v-select class="form-control" :options="paises" v-model="clienteDestinatario.pais" @change="obtenerEstados"></v-select>
              <label for="floatingSelect">Pais  <span class="text-danger fw-bold">[*]</span></label>
              </div>
            </div>


              <div class="col-md-4" v-if="resetFieldTypeHead">
              <div class="form-floating mb-3" >
              <vue3-simple-typeahead
                class="form-control"
                id="typeahead_estados"
                placeholder="Estado / Provincia"
                :items="estados"
                :defaultItem="clienteDestinatario.estado"
                :minInputLength="1"
                @selectItem="selectEstado"
                @onBlur="setEstado"
               >
              </vue3-simple-typeahead>
              <label for="floatingInput3" v-if="false">Estado / Provincia <span class="text-danger fw-bold" style="font-size: 10px;">*</span></label>
            </div> 
              </div>


                           
         <div class="col-md-4" v-if="resetFieldTypeHead">
              <div class="form-floating mb-3" >
              <vue3-simple-typeahead
                class="form-control"
                id="typeahead_ciudades"
                placeholder="Ciudad / Distrito"
                :items="ciudades"
                :defaultItem="clienteDestinatario.ciudad"
                :minInputLength="1"
                @selectItem="selectCiudad"
                @onBlur="setCiudad"
               >
              </vue3-simple-typeahead>
              <label for="floatingInput3" v-if="false">Ciudad / Distrito <span class="text-danger fw-bold" style="font-size: 10px;">*</span></label>
            </div> 
          </div>
          
        

              <div class="col-md-12">
               <div class="form-floating mb-3">
               <input v-model="clienteDestinatario.direccion" type="text" class="form-control" id="inputDireccion" placeholder="Direccion" autocomplete="off">
               <label for="floatingInput4">Direccion <span class="text-danger fw-bold">[*]</span></label>
               </div>
             </div>


              <div class="col-md-12">
              <div class="form-floating mb-3">
               <input v-model="clienteDestinatario.email" type="email" class="form-control" id="floatingInput5" placeholder="Telefono" autocomplete="off">
               <label for="floatingInput5">Email (Opcional)</label>
               </div>
               </div>

    </div>
 
 </div>
    <div class="buttons-modal-footer">
          <button v-if="modo == 0" @click="Crear" type="button" class="btn-modal-crear w-90 b-radius">Crear</button>
          <button v-if="modo == 1" @click="Actualizar" type="button" class="btn-modal-edit w-90 b-radius">Actualizar</button>
     </div>
    </Modal>
</template>

<script setup>
import axios from 'axios'
import Modal from '@/components/modals/Modal.vue'

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

import {getPaises, getEstados, getCiudades} from '@/helpers'
import {reactive, ref, inject, onMounted, defineEmits, defineProps} from 'vue'
import {useLoading} from 'vue-loading-overlay'
const $loading = useLoading();
import { useToast } from "vue-toastification";
const toast = useToast();
const modulo = ref('clientes-destinatarios')

const props = defineProps({
  titulo: String,
  cliente: Number,
  modo: Number,
  destinatario: Array
})
const emit = defineEmits(['toggleModalClienteDestinatario'])

const tiposDocs = ref([])
const paises = ref([])
const ciudades = ref([])
const estados = ref([])

const resetFieldTypeHead = ref(true)

const clienteDestinatario = reactive({
id: '',
id_cliente: props.cliente,
tipo_documento: '',
documento: '',
nombres: '',
apellidos: '',
telefono: '',
pais: 'Venezuela',
estado: '',
ciudad: '',
direccion: '',
email: ''
})

/**** Funcionalidad paises/estados/ciudades */

const setEstado = (e) => {
   clienteDestinatario.estado = e.input
}

const setCiudad = (e) => {
   clienteDestinatario.ciudad = e.input
}

const selectEstado = async (e) => {
  clienteDestinatario.estado = e
  await obtenerCiudades(e)
  document.querySelector("#typeahead_ciudades").focus()
}

const selectCiudad = (e) => {
  clienteDestinatario.ciudad = e
  document.querySelector('#inputDireccion').focus()
}

const obtenerEstados = async () => {
  const data = await getEstados(clienteDestinatario.pais)
  estados.value = data.array
}
  
const obtenerCiudades = async (estado) => {
  const data = await getCiudades(estado)
  ciudades.value = data.array
}
/*************************/

onMounted(async () => {
  tiposDocs.value = await inject('helpers').tiposDocs
  const paisesData = await getPaises()
  let jsonPaises = []
  for(let i in paisesData){
    jsonPaises.push(paisesData[i].pais)
  }
  paises.value = jsonPaises
  await obtenerEstados()
  if(props.modo == 1){
  clienteDestinatario.id = props.destinatario.id
  clienteDestinatario.tipo_documento = props.destinatario.tipo_documento
  clienteDestinatario.documento = props.destinatario.documento
  clienteDestinatario.nombres = props.destinatario.nombres
  clienteDestinatario.apellidos = props.destinatario.apellidos
  clienteDestinatario.telefono = props.destinatario.telefono
  clienteDestinatario.pais = props.destinatario.pais
  clienteDestinatario.estado = props.destinatario.estado
  clienteDestinatario.direccion = props.destinatario.direccion
  clienteDestinatario.email = props.destinatario.email
  resetFieldTypeHead.value = !resetFieldTypeHead.value
  setTimeout(() => {
    clienteDestinatario.ciudad = props.destinatario.ciudad
    resetFieldTypeHead.value = !resetFieldTypeHead.value
  }, 50);
  setTimeout(() => {
         document.querySelector('#inputDireccion').focus()
  }, 100);
  }
})

const toggleModal = () => {
    resetFieldTypeHead.value = !resetFieldTypeHead.value
    emit('toggleModalClienteDestinatario', false)
};

const Crear = () => {
let loader = $loading.show();

  axios.post(modulo.value, clienteDestinatario)
    .then(response => {
        loader.hide();
        if(response.data.status == 'ok'){
              reloadData()
              toggleModal()
              resetModal()
              toast.success(response.data.mensaje)
        }else{
              toast.error(response.data.mensaje)
        }
    })
    .catch(error => {
      loader.hide();
      toast.error('Error Creando Cliente Destinatario')
    });

}


const Actualizar = () => {
let loader = $loading.show();
  axios.put(`${modulo.value}/${clienteDestinatario.id}`, clienteDestinatario)
    .then(response => {
        loader.hide();
        if(response.data.status == 'ok'){
              reloadData()
              toggleModal()
              resetModal()
              toast.success(response.data.mensaje)
        }else{
              toast.error(response.data.mensaje)
        }
    })
    .catch(error => {
      loader.hide();
      toast.error('Error Editando Cliente Destinatario')
    });

}

const reloadData = () => {
if(document.querySelector('.btn-reload-destinatarios')){
   document.querySelector('.btn-reload-destinatarios').click();
}
}

const resetModal = () => {
clienteDestinatario.id = ''
clienteDestinatario.tipo_documento = ''
clienteDestinatario.documento  = ''   
clienteDestinatario.nombres = ''
clienteDestinatario.apellidos  = ''
clienteDestinatario.telefono  = ''
clienteDestinatario.pais  = 'Venezuela'
clienteDestinatario.estado  = ''
clienteDestinatario.ciudad  = ''
clienteDestinatario.email  = ''
}
</script>