import { defineStore } from 'pinia'

export const useProductsCartStore = defineStore('productsCart', {
  state: () => {
    return { 
        pais: '',
        articulos: [],
        total: 0
    }
  },
  actions: {

    addProduct(articulo) {
      if(!articulo.nombre && !articulo.cantidad
      && !articulo.costo && !articulo.divisa && !articulo.divisa_simbolo){
        return
      }
        for(let item in this.articulos) {
          if(this.articulos[item].producto.toLowerCase() === articulo.nombre.toLowerCase()) {
            this.articulos[item].cantidad ++;
            this.totalProductsCart()
            return;
          }
        }
        let item = {
            id: Date.now(),
            id_articulo: articulo.id ?? null,
            producto: articulo.nombre.toLowerCase(),
            pais: this.pais,
            divisa: articulo.divisa,
            divisa_simbolo: articulo.divisa_simbolo,
            costo: articulo.costo,
            cantidad: articulo.cantidad,
            bateria: articulo.bateria ? 1 : 0
        };

        this.articulos.push(item);
        this.totalProductsCart()
      },

      updateProduct(articulo) {
        if(!articulo.nombre && !articulo.cantidad
          && !articulo.costo && !articulo.divisa && !articulo.divisa_simbolo){
            return
        }
        let filter = this.articulos.filter(p => p.id == articulo.id)[0]
        if(filter){
          this.articulos.filter(p => p.id == producto.id)[0].producto = articulo.nombre
          this.articulos.filter(p => p.id == producto.id)[0].divisa = articulo.divisa
          this.articulos.filter(p => p.id == producto.id)[0].divisa_simbolo = articulo.divisa_simbolo
          this.articulos.filter(p => p.id == producto.id)[0].costo = articulo.costo
          this.articulos.filter(p => p.id == producto.id)[0].categoria = articulo.id_categoria
          this.articulos.filter(p => p.id == producto.id)[0].subcategoria = articulo.id_subcategoria
          this.articulos.filter(p => p.id == producto.id)[0].cantidad = articulo.cantidad
          this.articulos.filter(p => p.id == producto.id)[0].bateria = articulo.bateria
          this.totalProductsCart()
        }

      },

    vaciarArticulos(){
        this.pais = ''
        this.articulos = []
        this.total = 0
    },

    
    aumentarArticulos(articulo = '') {
        this.addProduct(articulo)
        this.totalProductsCart()
    },

    aumentarArticuloByCantidad(articulo = '', cantidad = 0){
      for(let ele of this.articulos) {
        if (ele.producto.toLowerCase() === articulo.toLowerCase()) {
          ele.cantidad = cantidad;
          break;
        }
      }
      this.totalProductsCart()
    },


    disminuirArticulos(articulo = ''){
        for(let item of this.articulos) {
            if(item.producto.toLowerCase() === articulo.toLowerCase()) {
              item.cantidad --;
              if(item.cantidad === 0) {
                this.articulos.splice(item, 1);
              }
              break;
            }
        }
        this.totalProductsCart()
      },


      eliminarProducto(articulo = ''){
        for(let item of this.articulos) {
          if(item.producto.toLowerCase() === articulo.toLowerCase()) {
            this.articulos.splice(item, 1);
            break;
          }
        }
      },


      totalProductsCart () {
        let totalProductos = 0;
        for(let item of this.articulos) {
            totalProductos += (item.costo * item.cantidad)
        }
        this.total = Number(totalProductos.toFixed(2));
      },

  }, 
})