/* eslint-disable no-console */

import { register } from 'register-service-worker'
import { useUpdateStore } from '@/store/updateApp'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {},
    registered () {},
    cached () {
      console.log('La aplicacion se almaceno en cache para su uso offline')
    },
    updatefound () {
      //console.log('La aplicación se esta actualizando...')
      const updateStore = useUpdateStore();
      updateStore.setDownloading();
    },
    updated (registration) {
      //console.log('Nueva versión detectada, Recargar App Porfavor')
      const updateStore = useUpdateStore();
      updateStore.setUpdateReady(registration);
    },
    offline () {
      console.log('No se encontro conexion a Internet. La aplicación se esta ejecutando en modo fuera de linea.')
    },
    error(error) {
      console.error('Error durante el registro de service worker:', error)
    }
  })
}
