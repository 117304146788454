import axios from 'axios'

export function roles(){
  return [
    {rol: 'master', roles: ['master']},
    {rol: 'aduanas', roles: ['master']},
    {rol: 'admin', roles: ['master', 'admin']},
    {rol: 'operador', roles: ['admin', 'master']},
  ]
  return 
}

export function getTiposEnvios(){
  let tipos_envios = [
    {id: 0, envio: 'Aereo'},
    {id: 1, envio: 'Maritimo'},
  ]
  return tipos_envios
}

export function getTimeActual(){
    var fecha = new Date(); 
    var hora = fecha.getHours();
    var actualTime = '';
   
    if(hora >= 0 && hora < 12){
        actualTime = "Buenos días";
    }
   
    if(hora >= 12 && hora < 18){
        actualTime = "Buenas tardes";
    }
   
    if(hora >= 18 && hora < 24){
        actualTime = "Buenas noches";
    }
    return {actualTime}
  }


  export function getDateChartsActual(){
    var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    var d = new Date();
    var mesActual = months[d.getMonth()];
    var yearActual = d.getFullYear();
    var dateChartActual = yearActual + "-" + mesActual
    return {dateChartActual}
  }


/************ Funciones api Paises */
export async function getPaises(){
  return [
    {
         "pais": "Zimbabwe",
         "nombre_corto": "ZW",
         "cod_telefono": 263
    },
    {
         "pais": "Zambia",
         "nombre_corto": "ZM",
         "cod_telefono": 260
    },
    {
         "pais": "Yugoslavia",
         "nombre_corto": "YU",
         "cod_telefono": 38
    },
    {
         "pais": "Yemen",
         "nombre_corto": "YE",
         "cod_telefono": 967
    },
    {
         "pais": "Western Sahara",
         "nombre_corto": "EH",
         "cod_telefono": 212
    },
    {
         "pais": "Wallis And Futuna Islands",
         "nombre_corto": "WF",
         "cod_telefono": 681
    },
    {
         "pais": "Virgin Islands (US)",
         "nombre_corto": "VI",
         "cod_telefono": 1340
    },
    {
         "pais": "Virgin Islands (British)",
         "nombre_corto": "VG",
         "cod_telefono": 1284
    },
    {
         "pais": "Vietnam",
         "nombre_corto": "VN",
         "cod_telefono": 84
    },
    {
         "pais": "Venezuela",
         "nombre_corto": "VE",
         "cod_telefono": 58
    },
    {
         "pais": "Vatican City State (Holy See)",
         "nombre_corto": "VA",
         "cod_telefono": 39
    },
    {
         "pais": "Vanuatu",
         "nombre_corto": "VU",
         "cod_telefono": 678
    },
    {
         "pais": "Uzbekistan",
         "nombre_corto": "UZ",
         "cod_telefono": 998
    },
    {
         "pais": "Uruguay",
         "nombre_corto": "UY",
         "cod_telefono": 598
    },
    {
         "pais": "United States Minor Outlying Islands",
         "nombre_corto": "UM",
         "cod_telefono": 1
    },
    {
         "pais": "United States",
         "nombre_corto": "US",
         "cod_telefono": 1
    },
    {
         "pais": "United Kingdom",
         "nombre_corto": "GB",
         "cod_telefono": 44
    },
    {
         "pais": "United Arab Emirates",
         "nombre_corto": "AE",
         "cod_telefono": 971
    },
    {
         "pais": "Ukraine",
         "nombre_corto": "UA",
         "cod_telefono": 380
    },
    {
         "pais": "Uganda",
         "nombre_corto": "UG",
         "cod_telefono": 256
    },
    {
         "pais": "Tuvalu",
         "nombre_corto": "TV",
         "cod_telefono": 688
    },
    {
         "pais": "Turks And Caicos Islands",
         "nombre_corto": "TC",
         "cod_telefono": 1649
    },
    {
         "pais": "Turkmenistan",
         "nombre_corto": "TM",
         "cod_telefono": 7370
    },
    {
         "pais": "Turkey",
         "nombre_corto": "TR",
         "cod_telefono": 90
    },
    {
         "pais": "Tunisia",
         "nombre_corto": "TN",
         "cod_telefono": 216
    },
    {
         "pais": "Trinidad And Tobago",
         "nombre_corto": "TT",
         "cod_telefono": 1868
    },
    {
         "pais": "Tonga",
         "nombre_corto": "TO",
         "cod_telefono": 676
    },
    {
         "pais": "Tokelau",
         "nombre_corto": "TK",
         "cod_telefono": 690
    },
    {
         "pais": "Togo",
         "nombre_corto": "TG",
         "cod_telefono": 228
    },
    {
         "pais": "Thailand",
         "nombre_corto": "TH",
         "cod_telefono": 66
    },
    {
         "pais": "Tanzania",
         "nombre_corto": "TZ",
         "cod_telefono": 255
    },
    {
         "pais": "Tajikistan",
         "nombre_corto": "TJ",
         "cod_telefono": 992
    },
    {
         "pais": "Taiwan",
         "nombre_corto": "TW",
         "cod_telefono": 886
    },
    {
         "pais": "Syria",
         "nombre_corto": "SY",
         "cod_telefono": 963
    },
    {
         "pais": "Switzerland",
         "nombre_corto": "CH",
         "cod_telefono": 41
    },
    {
         "pais": "Sweden",
         "nombre_corto": "SE",
         "cod_telefono": 46
    },
    {
         "pais": "Swaziland",
         "nombre_corto": "SZ",
         "cod_telefono": 268
    },
    {
         "pais": "Svalbard And Jan Mayen Islands",
         "nombre_corto": "SJ",
         "cod_telefono": 47
    },
    {
         "pais": "Suriname",
         "nombre_corto": "SR",
         "cod_telefono": 597
    },
    {
         "pais": "Sudan",
         "nombre_corto": "SD",
         "cod_telefono": 249
    },
    {
         "pais": "Sri Lanka",
         "nombre_corto": "LK",
         "cod_telefono": 94
    },
    {
         "pais": "Spain",
         "nombre_corto": "ES",
         "cod_telefono": 34
    },
    {
         "pais": "South Sudan",
         "nombre_corto": "SS",
         "cod_telefono": 211
    },
    {
         "pais": "South Georgia",
         "nombre_corto": "GS",
         "cod_telefono": 0
    },
    {
         "pais": "South Africa",
         "nombre_corto": "ZA",
         "cod_telefono": 27
    },
    {
         "pais": "Somalia",
         "nombre_corto": "SO",
         "cod_telefono": 252
    },
    {
         "pais": "Solomon Islands",
         "nombre_corto": "SB",
         "cod_telefono": 677
    },
    {
         "pais": "Smaller Territories of the UK",
         "nombre_corto": "XG",
         "cod_telefono": 44
    },
    {
         "pais": "Slovenia",
         "nombre_corto": "SI",
         "cod_telefono": 386
    },
    {
         "pais": "Slovakia",
         "nombre_corto": "SK",
         "cod_telefono": 421
    },
    {
         "pais": "Singapore",
         "nombre_corto": "SG",
         "cod_telefono": 65
    },
    {
         "pais": "Sierra Leone",
         "nombre_corto": "SL",
         "cod_telefono": 232
    },
    {
         "pais": "Seychelles",
         "nombre_corto": "SC",
         "cod_telefono": 248
    },
    {
         "pais": "Serbia",
         "nombre_corto": "RS",
         "cod_telefono": 381
    },
    {
         "pais": "Senegal",
         "nombre_corto": "SN",
         "cod_telefono": 221
    },
    {
         "pais": "Saudi Arabia",
         "nombre_corto": "SA",
         "cod_telefono": 966
    },
    {
         "pais": "Sao Tome and Principe",
         "nombre_corto": "ST",
         "cod_telefono": 239
    },
    {
         "pais": "San Marino",
         "nombre_corto": "SM",
         "cod_telefono": 378
    },
    {
         "pais": "Samoa",
         "nombre_corto": "WS",
         "cod_telefono": 684
    },
    {
         "pais": "Saint Vincent And The Grenadines",
         "nombre_corto": "VC",
         "cod_telefono": 1784
    },
    {
         "pais": "Saint Pierre and Miquelon",
         "nombre_corto": "PM",
         "cod_telefono": 508
    },
    {
         "pais": "Saint Lucia",
         "nombre_corto": "LC",
         "cod_telefono": 1758
    },
    {
         "pais": "Saint Kitts And Nevis",
         "nombre_corto": "KN",
         "cod_telefono": 1869
    },
    {
         "pais": "Saint Helena",
         "nombre_corto": "SH",
         "cod_telefono": 290
    },
    {
         "pais": "Rwanda",
         "nombre_corto": "RW",
         "cod_telefono": 250
    },
    {
         "pais": "Russia",
         "nombre_corto": "RU",
         "cod_telefono": 70
    },
    {
         "pais": "Romania",
         "nombre_corto": "RO",
         "cod_telefono": 40
    },
    {
         "pais": "Reunion",
         "nombre_corto": "RE",
         "cod_telefono": 262
    },
    {
         "pais": "Republic Of The Congo",
         "nombre_corto": "CG",
         "cod_telefono": 242
    },
    {
         "pais": "Qatar",
         "nombre_corto": "QA",
         "cod_telefono": 974
    },
    {
         "pais": "Puerto Rico",
         "nombre_corto": "PR",
         "cod_telefono": 1787
    },
    {
         "pais": "Portugal",
         "nombre_corto": "PT",
         "cod_telefono": 351
    },
    {
         "pais": "Poland",
         "nombre_corto": "PL",
         "cod_telefono": 48
    },
    {
         "pais": "Pitcairn Island",
         "nombre_corto": "PN",
         "cod_telefono": 0
    },
    {
         "pais": "Philippines",
         "nombre_corto": "PH",
         "cod_telefono": 63
    },
    {
         "pais": "Peru",
         "nombre_corto": "PE",
         "cod_telefono": 51
    },
    {
         "pais": "Paraguay",
         "nombre_corto": "PY",
         "cod_telefono": 595
    },
    {
         "pais": "Papua new Guinea",
         "nombre_corto": "PG",
         "cod_telefono": 675
    },
    {
         "pais": "Panama",
         "nombre_corto": "PA",
         "cod_telefono": 507
    },
    {
         "pais": "Palestinian Territory Occupied",
         "nombre_corto": "PS",
         "cod_telefono": 970
    },
    {
         "pais": "Palau",
         "nombre_corto": "PW",
         "cod_telefono": 680
    },
    {
         "pais": "Pakistan",
         "nombre_corto": "PK",
         "cod_telefono": 92
    },
    {
         "pais": "Oman",
         "nombre_corto": "OM",
         "cod_telefono": 968
    },
    {
         "pais": "Norway",
         "nombre_corto": "NO",
         "cod_telefono": 47
    },
    {
         "pais": "Northern Mariana Islands",
         "nombre_corto": "MP",
         "cod_telefono": 1670
    },
    {
         "pais": "Norfolk Island",
         "nombre_corto": "NF",
         "cod_telefono": 672
    },
    {
         "pais": "Niue",
         "nombre_corto": "NU",
         "cod_telefono": 683
    },
    {
         "pais": "Nigeria",
         "nombre_corto": "NG",
         "cod_telefono": 234
    },
    {
         "pais": "Niger",
         "nombre_corto": "NE",
         "cod_telefono": 227
    },
    {
         "pais": "Nicaragua",
         "nombre_corto": "NI",
         "cod_telefono": 505
    },
    {
         "pais": "New Zealand",
         "nombre_corto": "NZ",
         "cod_telefono": 64
    },
    {
         "pais": "New Caledonia",
         "nombre_corto": "NC",
         "cod_telefono": 687
    },
    {
         "pais": "Netherlands The",
         "nombre_corto": "NL",
         "cod_telefono": 31
    },
    {
         "pais": "Netherlands Antilles",
         "nombre_corto": "AN",
         "cod_telefono": 599
    },
    {
         "pais": "Nepal",
         "nombre_corto": "NP",
         "cod_telefono": 977
    },
    {
         "pais": "Nauru",
         "nombre_corto": "NR",
         "cod_telefono": 674
    },
    {
         "pais": "Namibia",
         "nombre_corto": "NA",
         "cod_telefono": 264
    },
    {
         "pais": "Myanmar",
         "nombre_corto": "MM",
         "cod_telefono": 95
    },
    {
         "pais": "Mozambique",
         "nombre_corto": "MZ",
         "cod_telefono": 258
    },
    {
         "pais": "Morocco",
         "nombre_corto": "MA",
         "cod_telefono": 212
    },
    {
         "pais": "Montserrat",
         "nombre_corto": "MS",
         "cod_telefono": 1664
    },
    {
         "pais": "Mongolia",
         "nombre_corto": "MN",
         "cod_telefono": 976
    },
    {
         "pais": "Monaco",
         "nombre_corto": "MC",
         "cod_telefono": 377
    },
    {
         "pais": "Moldova",
         "nombre_corto": "MD",
         "cod_telefono": 373
    },
    {
         "pais": "Micronesia",
         "nombre_corto": "FM",
         "cod_telefono": 691
    },
    {
         "pais": "Mexico",
         "nombre_corto": "MX",
         "cod_telefono": 52
    },
    {
         "pais": "Mayotte",
         "nombre_corto": "YT",
         "cod_telefono": 269
    },
    {
         "pais": "Mauritius",
         "nombre_corto": "MU",
         "cod_telefono": 230
    },
    {
         "pais": "Mauritania",
         "nombre_corto": "MR",
         "cod_telefono": 222
    },
    {
         "pais": "Martinique",
         "nombre_corto": "MQ",
         "cod_telefono": 596
    },
    {
         "pais": "Marshall Islands",
         "nombre_corto": "MH",
         "cod_telefono": 692
    },
    {
         "pais": "Man (Isle of)",
         "nombre_corto": "XM",
         "cod_telefono": 44
    },
    {
         "pais": "Malta",
         "nombre_corto": "MT",
         "cod_telefono": 356
    },
    {
         "pais": "Mali",
         "nombre_corto": "ML",
         "cod_telefono": 223
    },
    {
         "pais": "Maldives",
         "nombre_corto": "MV",
         "cod_telefono": 960
    },
    {
         "pais": "Malaysia",
         "nombre_corto": "MY",
         "cod_telefono": 60
    },
    {
         "pais": "Malawi",
         "nombre_corto": "MW",
         "cod_telefono": 265
    },
    {
         "pais": "Madagascar",
         "nombre_corto": "MG",
         "cod_telefono": 261
    },
    {
         "pais": "Macedonia",
         "nombre_corto": "MK",
         "cod_telefono": 389
    },
    {
         "pais": "Macau S.A.R.",
         "nombre_corto": "MO",
         "cod_telefono": 853
    },
    {
         "pais": "Luxembourg",
         "nombre_corto": "LU",
         "cod_telefono": 352
    },
    {
         "pais": "Lithuania",
         "nombre_corto": "LT",
         "cod_telefono": 370
    },
    {
         "pais": "Liechtenstein",
         "nombre_corto": "LI",
         "cod_telefono": 423
    },
    {
         "pais": "Libya",
         "nombre_corto": "LY",
         "cod_telefono": 218
    },
    {
         "pais": "Liberia",
         "nombre_corto": "LR",
         "cod_telefono": 231
    },
    {
         "pais": "Lesotho",
         "nombre_corto": "LS",
         "cod_telefono": 266
    },
    {
         "pais": "Lebanon",
         "nombre_corto": "LB",
         "cod_telefono": 961
    },
    {
         "pais": "Latvia",
         "nombre_corto": "LV",
         "cod_telefono": 371
    },
    {
         "pais": "Laos",
         "nombre_corto": "LA",
         "cod_telefono": 856
    },
    {
         "pais": "Kyrgyzstan",
         "nombre_corto": "KG",
         "cod_telefono": 996
    },
    {
         "pais": "Kuwait",
         "nombre_corto": "KW",
         "cod_telefono": 965
    },
    {
         "pais": "Korea South",
         "nombre_corto": "KR",
         "cod_telefono": 82
    },
    {
         "pais": "Korea North",
         "nombre_corto": "KP",
         "cod_telefono": 850
    },
    {
         "pais": "Kiribati",
         "nombre_corto": "KI",
         "cod_telefono": 686
    },
    {
         "pais": "Kenya",
         "nombre_corto": "KE",
         "cod_telefono": 254
    },
    {
         "pais": "Kazakhstan",
         "nombre_corto": "KZ",
         "cod_telefono": 7
    },
    {
         "pais": "Jordan",
         "nombre_corto": "JO",
         "cod_telefono": 962
    },
    {
         "pais": "Jersey",
         "nombre_corto": "XJ",
         "cod_telefono": 44
    },
    {
         "pais": "Japan",
         "nombre_corto": "JP",
         "cod_telefono": 81
    },
    {
         "pais": "Jamaica",
         "nombre_corto": "JM",
         "cod_telefono": 1876
    },
    {
         "pais": "Italy",
         "nombre_corto": "IT",
         "cod_telefono": 39
    },
    {
         "pais": "Israel",
         "nombre_corto": "IL",
         "cod_telefono": 972
    },
    {
         "pais": "Ireland",
         "nombre_corto": "IE",
         "cod_telefono": 353
    },
    {
         "pais": "Iraq",
         "nombre_corto": "IQ",
         "cod_telefono": 964
    },
    {
         "pais": "Iran",
         "nombre_corto": "IR",
         "cod_telefono": 98
    },
    {
         "pais": "Indonesia",
         "nombre_corto": "ID",
         "cod_telefono": 62
    },
    {
         "pais": "India",
         "nombre_corto": "IN",
         "cod_telefono": 91
    },
    {
         "pais": "Iceland",
         "nombre_corto": "IS",
         "cod_telefono": 354
    },
    {
         "pais": "Hungary",
         "nombre_corto": "HU",
         "cod_telefono": 36
    },
    {
         "pais": "Hong Kong S.A.R.",
         "nombre_corto": "HK",
         "cod_telefono": 852
    },
    {
         "pais": "Honduras",
         "nombre_corto": "HN",
         "cod_telefono": 504
    },
    {
         "pais": "Heard and McDonald Islands",
         "nombre_corto": "HM",
         "cod_telefono": 0
    },
    {
         "pais": "Haiti",
         "nombre_corto": "HT",
         "cod_telefono": 509
    },
    {
         "pais": "Guyana",
         "nombre_corto": "GY",
         "cod_telefono": 592
    },
    {
         "pais": "Guinea-Bissau",
         "nombre_corto": "GW",
         "cod_telefono": 245
    },
    {
         "pais": "Guinea",
         "nombre_corto": "GN",
         "cod_telefono": 224
    },
    {
         "pais": "Guernsey and Alderney",
         "nombre_corto": "XU",
         "cod_telefono": 44
    },
    {
         "pais": "Guatemala",
         "nombre_corto": "GT",
         "cod_telefono": 502
    },
    {
         "pais": "Guam",
         "nombre_corto": "GU",
         "cod_telefono": 1671
    },
    {
         "pais": "Guadeloupe",
         "nombre_corto": "GP",
         "cod_telefono": 590
    },
    {
         "pais": "Grenada",
         "nombre_corto": "GD",
         "cod_telefono": 1473
    },
    {
         "pais": "Greenland",
         "nombre_corto": "GL",
         "cod_telefono": 299
    },
    {
         "pais": "Greece",
         "nombre_corto": "GR",
         "cod_telefono": 30
    },
    {
         "pais": "Gibraltar",
         "nombre_corto": "GI",
         "cod_telefono": 350
    },
    {
         "pais": "Ghana",
         "nombre_corto": "GH",
         "cod_telefono": 233
    },
    {
         "pais": "Germany",
         "nombre_corto": "DE",
         "cod_telefono": 49
    },
    {
         "pais": "Georgia",
         "nombre_corto": "GE",
         "cod_telefono": 995
    },
    {
         "pais": "Gambia The",
         "nombre_corto": "GM",
         "cod_telefono": 220
    },
    {
         "pais": "Gabon",
         "nombre_corto": "GA",
         "cod_telefono": 241
    },
    {
         "pais": "French Southern Territories",
         "nombre_corto": "TF",
         "cod_telefono": 0
    },
    {
         "pais": "French Polynesia",
         "nombre_corto": "PF",
         "cod_telefono": 689
    },
    {
         "pais": "French Guiana",
         "nombre_corto": "GF",
         "cod_telefono": 594
    },
    {
         "pais": "France",
         "nombre_corto": "FR",
         "cod_telefono": 33
    },
    {
         "pais": "Finland",
         "nombre_corto": "FI",
         "cod_telefono": 358
    },
    {
         "pais": "Fiji Islands",
         "nombre_corto": "FJ",
         "cod_telefono": 679
    },
    {
         "pais": "Faroe Islands",
         "nombre_corto": "FO",
         "cod_telefono": 298
    },
    {
         "pais": "Falkland Islands",
         "nombre_corto": "FK",
         "cod_telefono": 500
    },
    {
         "pais": "Ethiopia",
         "nombre_corto": "ET",
         "cod_telefono": 251
    },
    {
         "pais": "Estonia",
         "nombre_corto": "EE",
         "cod_telefono": 372
    },
    {
         "pais": "Eritrea",
         "nombre_corto": "ER",
         "cod_telefono": 291
    },
    {
         "pais": "Equatorial Guinea",
         "nombre_corto": "GQ",
         "cod_telefono": 240
    },
    {
         "pais": "El Salvador",
         "nombre_corto": "SV",
         "cod_telefono": 503
    },
    {
         "pais": "Egypt",
         "nombre_corto": "EG",
         "cod_telefono": 20
    },
    {
         "pais": "Ecuador",
         "nombre_corto": "EC",
         "cod_telefono": 593
    },
    {
         "pais": "East Timor",
         "nombre_corto": "TP",
         "cod_telefono": 670
    },
    {
         "pais": "Dominican Republic",
         "nombre_corto": "DO",
         "cod_telefono": 1809
    },
    {
         "pais": "Dominica",
         "nombre_corto": "DM",
         "cod_telefono": 1767
    },
    {
         "pais": "Djibouti",
         "nombre_corto": "DJ",
         "cod_telefono": 253
    },
    {
         "pais": "Denmark",
         "nombre_corto": "DK",
         "cod_telefono": 45
    },
    {
         "pais": "Democratic Republic Of The Congo",
         "nombre_corto": "CD",
         "cod_telefono": 243
    },
    {
         "pais": "Czech Republic",
         "nombre_corto": "CZ",
         "cod_telefono": 420
    },
    {
         "pais": "Cyprus",
         "nombre_corto": "CY",
         "cod_telefono": 357
    },
    {
         "pais": "Cuba",
         "nombre_corto": "CU",
         "cod_telefono": 53
    },
    {
         "pais": "Croatia (Hrvatska)",
         "nombre_corto": "HR",
         "cod_telefono": 385
    },
    {
         "pais": "Cote D'Ivoire (Ivory Coast)",
         "nombre_corto": "CI",
         "cod_telefono": 225
    },
    {
         "pais": "Costa Rica",
         "nombre_corto": "CR",
         "cod_telefono": 506
    },
    {
         "pais": "Cook Islands",
         "nombre_corto": "CK",
         "cod_telefono": 682
    },
    {
         "pais": "Comoros",
         "nombre_corto": "KM",
         "cod_telefono": 269
    },
    {
         "pais": "Colombia",
         "nombre_corto": "CO",
         "cod_telefono": 57
    },
    {
         "pais": "Cocos (Keeling) Islands",
         "nombre_corto": "CC",
         "cod_telefono": 672
    },
    {
         "pais": "Christmas Island",
         "nombre_corto": "CX",
         "cod_telefono": 61
    },
    {
         "pais": "China",
         "nombre_corto": "CN",
         "cod_telefono": 86
    },
    {
         "pais": "Chile",
         "nombre_corto": "CL",
         "cod_telefono": 56
    },
    {
         "pais": "Chad",
         "nombre_corto": "TD",
         "cod_telefono": 235
    },
    {
         "pais": "Central African Republic",
         "nombre_corto": "CF",
         "cod_telefono": 236
    },
    {
         "pais": "Cayman Islands",
         "nombre_corto": "KY",
         "cod_telefono": 1345
    },
    {
         "pais": "Cape Verde",
         "nombre_corto": "CV",
         "cod_telefono": 238
    },
    {
         "pais": "Canada",
         "nombre_corto": "CA",
         "cod_telefono": 1
    },
    {
         "pais": "Cameroon",
         "nombre_corto": "CM",
         "cod_telefono": 237
    },
    {
         "pais": "Cambodia",
         "nombre_corto": "KH",
         "cod_telefono": 855
    },
    {
         "pais": "Burundi",
         "nombre_corto": "BI",
         "cod_telefono": 257
    },
    {
         "pais": "Burkina Faso",
         "nombre_corto": "BF",
         "cod_telefono": 226
    },
    {
         "pais": "Bulgaria",
         "nombre_corto": "BG",
         "cod_telefono": 359
    },
    {
         "pais": "Brunei",
         "nombre_corto": "BN",
         "cod_telefono": 673
    },
    {
         "pais": "British Indian Ocean Territory",
         "nombre_corto": "IO",
         "cod_telefono": 246
    },
    {
         "pais": "Brasil",
         "nombre_corto": "BR",
         "cod_telefono": 55
    },
    {
         "pais": "Bouvet Island",
         "nombre_corto": "BV",
         "cod_telefono": 0
    },
    {
         "pais": "Botswana",
         "nombre_corto": "BW",
         "cod_telefono": 267
    },
    {
         "pais": "Bosnia and Herzegovina",
         "nombre_corto": "BA",
         "cod_telefono": 387
    },
    {
         "pais": "Bolivia",
         "nombre_corto": "BO",
         "cod_telefono": 591
    },
    {
         "pais": "Bhutan",
         "nombre_corto": "BT",
         "cod_telefono": 975
    },
    {
         "pais": "Bermuda",
         "nombre_corto": "BM",
         "cod_telefono": 1441
    },
    {
         "pais": "Benin",
         "nombre_corto": "BJ",
         "cod_telefono": 229
    },
    {
         "pais": "Belize",
         "nombre_corto": "BZ",
         "cod_telefono": 501
    },
    {
         "pais": "Belgium",
         "nombre_corto": "BE",
         "cod_telefono": 32
    },
    {
         "pais": "Belarus",
         "nombre_corto": "BY",
         "cod_telefono": 375
    },
    {
         "pais": "Barbados",
         "nombre_corto": "BB",
         "cod_telefono": 1246
    },
    {
         "pais": "Bangladesh",
         "nombre_corto": "BD",
         "cod_telefono": 880
    },
    {
         "pais": "Bahrain",
         "nombre_corto": "BH",
         "cod_telefono": 973
    },
    {
         "pais": "Bahamas The",
         "nombre_corto": "BS",
         "cod_telefono": 1242
    },
    {
         "pais": "Azerbaijan",
         "nombre_corto": "AZ",
         "cod_telefono": 994
    },
    {
         "pais": "Austria",
         "nombre_corto": "AT",
         "cod_telefono": 43
    },
    {
         "pais": "Australia",
         "nombre_corto": "AU",
         "cod_telefono": 61
    },
    {
         "pais": "Aruba",
         "nombre_corto": "AW",
         "cod_telefono": 297
    },
    {
         "pais": "Armenia",
         "nombre_corto": "AM",
         "cod_telefono": 374
    },
    {
         "pais": "Argentina",
         "nombre_corto": "AR",
         "cod_telefono": 54
    },
    {
         "pais": "Antigua And Barbuda",
         "nombre_corto": "AG",
         "cod_telefono": 1268
    },
    {
         "pais": "Antarctica",
         "nombre_corto": "AQ",
         "cod_telefono": 0
    },
    {
         "pais": "Anguilla",
         "nombre_corto": "AI",
         "cod_telefono": 1264
    },
    {
         "pais": "Angola",
         "nombre_corto": "AO",
         "cod_telefono": 244
    },
    {
         "pais": "Andorra",
         "nombre_corto": "AD",
         "cod_telefono": 376
    },
    {
         "pais": "American Samoa",
         "nombre_corto": "AS",
         "cod_telefono": 1684
    },
    {
         "pais": "Algeria",
         "nombre_corto": "DZ",
         "cod_telefono": 213
    },
    {
         "pais": "Albania",
         "nombre_corto": "AL",
         "cod_telefono": 355
    },
    {
         "pais": "Afghanistan",
         "nombre_corto": "AF",
         "cod_telefono": 93
    }
]
}

/*export async function getPaises(){
    let response = await axios.get('/paises')
    return response.data
  }*/


  export async function getEstados(pais){
    let response = await axios.get(`/paises/estados/${pais}`)
    return response.data
  }


  export async function getCiudades(estado){
    let response = await axios.get(`/paises/ciudades/${estado}`)
    return response.data
  }
  /************** Fin funciones api Paises */


  /********** Funciones api clientes / destinatarios  */
  export async function getClientes(search){
     let response = (await axios.get(`/clientes/search?q=nombres_apellidos&data=${search}`)).data
     return response
}


  export async function getDocumentosClientes(documento){
    let response = (await axios.get(`/clientes/search?q=documentos&data=${documento}`)).data
    return response
  }

  export async function getClienteByDocumento(documento){
    let response = await axios.get(`/clientes/search?q=documento&data=${documento}`)
    return response.data
  }


  export async function getClienteById(id){
    let response = await axios.get(`/clientes?search=id&q=${id}`)
    return response.data
  }


  export async function getDestinatarios(search){
     let response = (await axios.get(`/clientes-destinatarios/search?q=nombres_apellidos&data=${search}`)).data
     return response
 }
 

  export async function getClientesDestinatarios(id_cliente){
    let response = await axios.get(`/clientes-destinatarios/by-cliente/${id_cliente}`)
    return response.data
  }

  /**********Fin Funciones api clientes  */


  /***** Tipos de documentos ***/

  export function tiposDocumentos(){
    let tipos_documentos = [
      {id: 1, tipo: 'pasaporte'},
      {id: 2, tipo: 'cedula'},
      {id: 3, tipo: 'cedula extranjera'},
      {id: 4, tipo: 'dni'},
      {id: 5, tipo: 'ruc'},
      {id: 6, tipo: 'carnet extranjeria'},
      {id: 7, tipo: 'ptp'},
      {id: 8, tipo: 'carnet refugiado'},
      {id: 9, tipo: 'rif'},
      {id: 10, tipo: 'otros'},
    ]
    return {tipos_documentos}
  }

  /***********Fin tipo de documentos********** */


  
  /***** Tipos de documentos ***/

  export function divisaPais(){
    let divisas = [
      {
        id: 1, 
        pais: 'venezuela',
        divisas: [{id: 1, divisa: 'Bolivares', simbolo: 'Bs'}, {id: 2, divisa: 'Dolares', simbolo: '$'}],
      },
      {
        id: 2, 
        pais: 'peru',
        divisas:[{id: 1, divisa: 'Soles', simbolo: 'S/.'}, {id: 2, divisa: 'Dolares', simbolo: '$'}],
      },
      {
        id: 3, 
        pais: 'chile',
        divisas:[{id: 1, divisa: 'Pesos Chilenos', simbolo: '$'}, {id: 2, divisa: 'Dolares', simbolo: '$'}],
      },
      {
        id: 4, 
        pais: 'ecuador',
        divisas: [{id: 1, divisa: 'Dolares', simbolo: '$'}],
      },
      {
        id: 5, 
        pais: 'colombia',
        divisas: [{id: 1, divisa: 'Pesos Colombianos', simbolo: '$'}, {id: 2, divisa: 'Dolares', simbolo: '$'}],
      },
      {
        id: 6, 
        pais: 'argentina',
        divisas: [{id: 1, divisa: 'Pesos Argentinos', simbolo: '$'}, {id: 2, divisa: 'Dolares', simbolo: '$'}],
      },
      {
        id: 7, 
        pais: 'bolivia',
        divisas: [{id: 1, divisa: 'Bolivianos', simbolo: 'B$'}, {id: 2, divisa: 'Dolares', simbolo: '$'}],
      },
      {
        id: 8, 
        pais: 'brasil',
        divisas: [{id: 1, divisa: 'Reals', simbolo: 'R$'}, {id: 2, divisa: 'Dolares', simbolo: '$'}],
      },
      {
        id: 9, 
        pais: 'spain',
        divisas: [{id: 1, divisa: 'Euros', simbolo: '€'}, {id: 2, divisa: 'Dolares', simbolo: '$'}],
      },
      {
        id: 10, 
        pais: 'united states',
        divisas: [{id: 1, divisa: 'Dolares', simbolo: '$'}],
      },
    ]
    return divisas
  }


  export function dataDivisaByPais(pais){
    const divisas = divisaPais()
    let divisaFilter = divisas.filter(item => item.pais == pais.toLowerCase())[0]
    return divisaFilter
  }

  /***********Fin tipo de documentos********** */


  /********** Medios de pago */
 
  export function getMediosPago(){
    let metodos_pago = 
    [
      {status: 1, metodo: 'Efectivo'},
      {status: 1, metodo: 'Transferencia'},
      {status: 1, metodo: 'Deposito'},
      {status: 1, metodo: 'Credito'},
      {status: 1, metodo: 'Zelle'},
      {status: 1, metodo: 'Yape'},
      {status: 1, metodo: 'Plin'},
      {status: 1, metodo: 'Agora'},
    ]
    return metodos_pago
  }

  /********** Fin Medios de pago */


  /********** Funcion Api Tasas Cambios  */
      export async function getTasasCambio(search){
        try{
          let response = (await axios.get(`/tasa-cambio`)).data
          return response
        }catch(error){
          return []
        }
      }
      /**************** Fin Api Articulos */


    /********** Funcion Api Articulos  */
    export async function getArticulos(search){
      try{
        let response = await axios.get(`/articulos-historial/${search}`)
        return response.data
      }catch(error){
        return []
      }
    }
    /**************** Fin Api Articulos */


  /********** Funcion Trackings  */
 export async function getTrackingList(){
    let list_tracking = [
      {id: 1, tracking: 'Creacion', descripcion: 'Paquete recepcionado en oficina'},
      {id: 3, tracking: 'Recibido', descripcion: 'Tu paquete fue recibido en nuestros almacenes / sedes.'},
      {id: 4, tracking: 'Almacén', descripcion: 'Su paquete ha sido envalijado en {{PAIS_ORIGEN}}, y en pronto será enviado a su destino {{PAIS_DESTINO}}.'},
      {id: 5, tracking: 'Aduana origen', descripcion: 'Su paquete ha sido entregado a la aduana en {{PAIS_ORIGEN}} donde se le cargarán los aranceles de salida del país o aranceles. '},
      {id: 6, tracking: 'En Transito', descripcion: 'Su paquete paquete se encuentra en translado al destino en {{PAIS_DESTINO}}'},
      {id: 7, tracking: 'Almacén ADT', descripcion: 'Mercancía no despachada a la espera de recibir el transbordo hacia {{PAIS_DESTINO}}.'},
      {id: 8, tracking: 'Aduana destino', descripcion: 'Su paquete ha llegado a la aduana en {{PAIS_DESTINO}} donde se le cargarán los aranceles de ingreso al país y será tramitado para el envío al Centro de Distribución'},
      {id: 9, tracking: 'Recibido en Distribución', descripcion: 'El paquete se encuentra en el Centro de Distribución para su envío a la Sucursal seleccionada.'},
      {id: 10, tracking: 'Transferido', descripcion: 'Su paquete fue enviado a la sucursal de retiro. '},
      {id: 11, tracking: 'Disponible', descripcion: 'Ya puedes retirar tu paquete.'},
    ]
    const response = (await axios.get('/tracking/list')).data
    return response
}
/**************** Fin trackings */


/********* Couriers Api */

export async function getCouriers (id){
  let response = (await axios.get('courier')).data
  return response
}


export async function getCouriersOffices(id_courier){
  let response = (await axios.get('courier-oficinas/' + id_courier)).data
  return response
}

/***************** */


/****** Guias Api */
export async function getInfoGuiaById(guideId){
  let response = (await axios.get('guias/info/' + guideId)).data
  return response
}


export async function getGuiaById(guideId){
  let response = (await axios.get('guias/' + guideId)).data
  return response.data
}


export async function validateGuidesManifest(guides){
  let response = (await axios.post('cargas/validate-guides', { guides: guides})).data
  return response
}
/******************/


/********** Manifiestos Api */
export async function getGuiasOffice(){
  let response = (await axios.get('manifiestos/get-guias')).data
  return response.guias
}

export async function getManifiestoById(id){
  let response = (await axios.get(`manifiestos/${id}`)).data
  return response
}

export async function getGuiasByIdManifiesto(id){
  let response = (await axios.get(`manifiestos/guias/${id}`)).data
  return response
}
/******************/


/********** Cargas Api */
export async function getManifiestosOffice(){
  let response = (await axios.get('cargas/get-manifiestos')).data
  return response.manifiestos
}

export async function getCargaById(id){
  let response = (await axios.get(`cargas/${id}`)).data
  return response
}
/******************/