<template>
    <div class="form-floating">
        <select class="form-select fw-bold" v-model="selected" @change="setData">
          <option v-for="(value, key) in Object.entries(trackingList)" :key="key" :value="value[0]">({{key+1}}) {{value[1].status}}</option>
        </select>
        <label for="floatingSelect">{{titulo ? titulo : 'Lista De Trackings'}}</label>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import {getTrackingList} from '@/helpers'
const props = defineProps({
    select: '',
    titulo: '',
})

const emits = defineEmits(['selected'])
const trackingList = ref([])
const selected = ref()


const setData = () => {
    emits('selected', selected.value)
}


onMounted(async () => {
    trackingList.value = await getTrackingList()
    if(props.select){

    }
})
</script>