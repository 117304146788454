<template>
    <Modal :titulo="`Tracking Zoom`" @close="$emit('toggleModal')" :modalActive="true">
        
     <div class="m-auto position-relative">
            <div class="form-floating mb-3">
                <input
				:disabled="loading"
                v-on:keyup.enter="getTracking"
                v-model="guia_courier"
                type="tel"
                class="form-control fs-xl-2 b-radius text-center"
                placeholder="Número de rastreo"
                required
                />
                <label class="text-center" for="floatingInput">NRO TRACKING</label>
            </div>

       
            <i class="fas fa-search icon-search" @click="getTracking"></i>
      </div>


      <div class="h-scroll" style="position:relative; top: 1rem;">

           <div v-if="loading" class="d-flex" v-for="item in 5" :key="item">

                <div class="d-flex flex-column">
                <span class="number-consult-guia PicLoaderCircular animate"></span>
                <span class="separador-number-guia-loading comment br animate"></span>
                </div>


                <div class="ms-3 d-flex flex-column w-100">
                    <div class="fecha d-flex justify-content-between">
                        <span class="comment br animate" style="width: 80px; height: 20px;"></span>
                        <span class="comment br animate" style="width: 80px; height: 20px;"></span>
                    </div>
                    <span class="text-primary fw-bold text-center mt-2 comment br animate m-auto" style="width: 280px; height: 15px;"></span>
                    <span class="text-muted comment br animate" style="height: 15px;"></span>

                    <span class="text-muted comment br animate" style="height: 4px;"></span>
                </div>

            </div>



     <div v-else class="d-flex" v-for="(item, index) in tracking.tracking">
     <div class="d-flex flex-column">
     <span class="number-consult-guia">{{index + 1}}</span>
     <span class="separador-number-guia"></span>
     </div>

     <div class="ms-3 d-flex flex-column w-100">
         <div class="fecha d-flex justify-content-between">
             <span>{{item.fecha?.split(' ')[0]}}</span>
             <span>{{item.fecha?.split(' ')[1]}}</span>
         </div>
         <span class="text-primary fw-bold text-center mt-2 mb-2">{{item.estado}}</span>
         <span class="text-muted">{{item.descripcion}}</span>
         <div class="hr-border-bottom text-secundary"></div>
     </div>
     </div>

    </div>

    </Modal>
</template>

<script setup>
import axios from 'axios'
import {ref} from 'vue'
import { useToast } from "vue-toastification";
const toast = useToast();
const emit = defineEmits(['toggleModal'])
const guia_courier = ref('')
const tracking = ref([])
const loading = ref(false)


const getTracking = async () => {
    if(loading.value){
        return
    }
    loading.value = true
    try {
    let response = (await axios.post('/tracking/zoom', {guia: guia_courier.value})).data
    loading.value = false
    if(response.tracking){
    tracking.value = response
    }else{
    toast.error("No se obtuvieron trackings de la guía solicitada", 3000)
    tracking.value = []  
    }
    } catch (error) {
        loading.value = false
        toast.error("Exeption: " + error)
    }
}
</script>

<style scoped>
.icon-search{
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 1rem;
    padding: 0.8rem;
    font-size: 2rem;
}
</style>