<template>
    <div class="form-floating position-relative">
        <div class="animate input-loader" v-if="loading"></div>
        <v-select v-else :disabled="disabled" class="form-control" v-model="selectData" :options="data"
            :get-option-label="(option) => option.nombre" :reduce="data => data" 
            @option:selected="setData"
            >

            <template #option="{ codigo, nombre, usuario }">
             <b>{{ codigo?.toUpperCase() }}</b>-{{ nombre }} {{ $auth.user.rol == 'master' ? `(${usuario})` : '' }}
            </template>
            
          <template v-slot:no-options>
          Oficina no encontrada...
          </template>
        </v-select>
        <label for="floatingSelect6">{{ titulo ? titulo : 'Selecciona Oficina'}} <span v-if="required"
                class="text-danger fw-bold">[*]</span></label>
        <i v-if="reload" class="bi bi-arrow-clockwise icon-refresh" @click="loadData" title="Recargar data"></i>
    </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import axios from 'axios';
const props = defineProps({
    required: null,
    reload: null,
    titulo: null,
    select: null,
    id_usuario: null,
    disabled: {type: Boolean, default: false}
})
const loading = ref('')
const data = ref([])
const selectData = ref(props.select)
const emit = defineEmits(['selected'])

const setData = () => {
    if(!selectData.value){
        return
    }
    emit('selected', selectData.value)
}


/*
:filterable="false" 
@search="fetchData"
*/

const fetchData = async (search, loading) => {
    if(!search.length){
        return
    }
    loading(true);
    let response = (await axios.get('oficinas' + (search ? `?search=filter&q=${search}&limit=1` : `?search=filter&limit=3`))).data
    loading(false);
    if(response.length > 0){
        data.value = response
    }
}

const loadData = async () => {
    loading.value = true
    let response = (await axios.get(`oficinas`)).data?.data
    loading.value = false
    data.value = response
    if(props.select){
        selectData.value = response.filter(item => item.id == props.select)[0]
    }
    if(props.id_usuario){
        data.value = response.filter(item => item.id_usuario == props.id_usuario)
    }
    setData()
}

onMounted(() => {
    loadData()
})


watch(() => props.select, newData => {
    if(newData){
        selectData.value = data.value.filter(item => item.id == newData)[0]
    }
})
</script>

<style scoped>
.icon-refresh {
    cursor: pointer;
    position: absolute;
    top: 9px;
    right: 3rem;
    padding: 1.2rem;
    font-weight: bold;
    color: var(--primary)
}
</style>