<template>
    <!-- Modal de descarga -->
    <Modal :modalActive="updateStore.showDownloadingModal" :btnClose="false">
      <div>
        <img style="height: 150px;" :src="newUpdateImg" />
        <h2 class="mt-3">Descargando Actualización...</h2>
        <p>Estamos descargando la última versión de la aplicación. Por favor, espera.</p>
      </div>
    </Modal>
  
    <!-- Modal de actualización -->
    <Modal :modalActive="updateStore.showUpdateModal" :btnClose="false">
      <div>
        <img style="height: 200px;" :src="successImg" />
        <h2>¡Nueva versión disponible!</h2>
        <p>Es necesario reiniciar la aplicación. Por favor, espera...</p>
        <button v-if="false" class="btn-app w-50" @click="updateStore.confirmUpdate">Actualizar ahora</button>
      </div>
    </Modal>
  </template>
  
  <script setup>
  import { watch } from 'vue';
  import newUpdateImg from '@/assets/img/recursos/new_update.png'
  import successImg from '@/assets/img/recursos/success.gif'
  import { useUpdateStore } from '@/store/updateApp';
  const updateStore = useUpdateStore();

  watch(() => updateStore.registration, data => {
    if(data){
        setTimeout(() => {
            updateStore.confirmUpdate()
        }, 1000);
    }
  })
  </script>