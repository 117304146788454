import {
  createRouter,
  createWebHistory,
} from "vue-router";
import { useAuthStore } from "@/store/auth";

function lazyLoad(view) {
  return () => import(`@/views/${view}.vue`);
}

function lazyLoadComponents(view) {
  return () => import(`@/components/${view}.vue`);
}



const firtRederict = async (to, from, next) => {
  const token = localStorage.getItem("auth_token") ? true : false;
  await useAuthStore().getUser()
  if (!useAuthStore().autenticado || !token) {
    next('/login') ;
  } else {
    switch(useAuthStore().user.rol){
      case 'operador':
      case 'aduanas':
        next('/guias')
      break
      default:
        next()
      break
    }
  }
};


const redirectHome = (to, from, next) => {
  const token = localStorage.getItem("auth_token") ? true : false;
  if (useAuthStore().autenticado || token) {
    next("/");
  } else {
    next();
  }
};

const checkAuth = async (to, from, next) => {
  const token = localStorage.getItem("auth_token") ? true : false;
  await useAuthStore().getUser()
  if (!useAuthStore().autenticado || !token) {
    window.location = "/login";
  } else {
    next();
  }
};


const rolAccess = (...roles) => async (to, from, next) => {
  const token = localStorage.getItem("auth_token") ? true : false;
  if (!useAuthStore().autenticado || !token) {
      window.location = "/login"
  } else {
    await useAuthStore().getUser()
    const rol = useAuthStore().user.rol

    if((roles).includes(rol)){
      next();
    }else{
      window.location = "/"
    }

  }
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: lazyLoad("Index"),
    meta: { layout: "autenticado", title: `Inicio`},
    beforeEnter: firtRederict,
  },

  {
    path: "/oficinas",
    name: "Oficinas",
    component: lazyLoad("Oficinas/Oficinas"),
    meta: { layout: "autenticado", title: `Oficinas`},
    beforeEnter: rolAccess('master','admin'),
  },
  {
    path: "/oficinas/:id(\\d+)/edit",
    name: "Oficina Edit",
    component: lazyLoad("Oficinas/Oficina-Edit"),
    meta: { layout: "autenticado", title: `Editando Oficina`},
    beforeEnter: rolAccess('master','admin'),
  },


  {
    path: "/courier",
    name: "Courier",
    component: lazyLoad("Courier/Courier"),
    meta: { layout: "autenticado", title: `Courier`},
    beforeEnter: rolAccess('master'),
  },



  {
    path: "/guias/:tipo_guia?",
    name: "Guias",
    component: lazyLoad("Guias/Index"),
    meta: { layout: "autenticado", title: `Guias`},
    beforeEnter: checkAuth,
  },

  {
    path: "/guias/:tipo_guia?/crear",
    name: "Guia-Nueva",
    component: lazyLoadComponents("guias/Wizard-Guia"),
    meta: { layout: "autenticado", title: `Nueva Guía`},
    beforeEnter: rolAccess('operador'),
  },

  {
    path: "/guias/:id(\\d+)",
    name: "Guia-Edit",
    component: lazyLoadComponents("guias/Wizard-Guia"),
    meta: { layout: "autenticado", title: `Visualizando Guía`},
    beforeEnter: rolAccess('master', 'admin', 'operador'),
  },




  {
    path: "/recepciones",
    name: "Recepciones",
    component: lazyLoad("Guias/Recepcion/index"),
    meta: { layout: "autenticado", title: `Recepción de guías`},
    beforeEnter: rolAccess('master', 'admin'),
  },
  {
    path: "/recepciones/:id(\\d+)/edit",
    name: "RecepcionesDetalles",
    component: lazyLoad("Guias/Recepcion/new_details"),
    meta: { layout: "autenticado", title: `Recepción`},
    beforeEnter: rolAccess('master', 'admin'),
  },
  {
    path: "/recepciones/create",
    name: "RecepcionesNueva",
    component: lazyLoad("Guias/Recepcion/new_details"),
    meta: { layout: "autenticado", title: `Recepción`},
    beforeEnter: rolAccess('master', 'admin'),
  },

  {
    path: "/manifiestos",
    name: "Manifiestos",
    component: lazyLoad("Guias/Manifiestos/index"),
    meta: { layout: "autenticado", title: `Manifiestos`},
    beforeEnter: rolAccess('master', 'admin', 'operador'),
  },
  {
    path: "/manifiestos/create",
    name: "Manifiestos-Nuevo",
    component: lazyLoad("Guias/Manifiestos/new_edit"),
    meta: { layout: "autenticado", title: `Nuevo Manifiesto`},
    beforeEnter: rolAccess('operador'),
  },
  {
    path: "/manifiestos/:id(\\d+)/edit",
    name: "Editacion-Manifiestos",
    component: lazyLoad("Guias/Manifiestos/new_edit"),
    meta: { layout: "autenticado", title: `Editando Manifiestos`},
    beforeEnter: rolAccess('master', 'admin', 'operador'),
  },
  
  {
    path: "/cargas",
    name: "Cargas",
    component: lazyLoad("Guias/Cargas/index"),
    meta: { layout: "autenticado", title: `Cargas`},
    beforeEnter: rolAccess('master'),
  },
  {
    path: "/cargas/nueva",
    name: "Cargas-Nueva",
    component: lazyLoad("Guias/Cargas/NuevaAndEdit"),
    meta: { layout: "autenticado", title: `Nueva Carga`},
    beforeEnter: rolAccess('master'),
  },
  {
    path: "/cargas/:id(\\d+)/edit",
    name: "Editacion-Carga",
    component: lazyLoad("Guias/Cargas/NuevaAndEdit"),
    meta: { layout: "autenticado", title: `Editando Carga`},
    beforeEnter: rolAccess('master'),
  },
  {
    path: "/cargas/:id(\\d+)/zoom",
    name: "Carga-Courier-Zoom",
    component: lazyLoad("Guias/Cargas/ZoomSync"),
    meta: { layout: "autenticado", title: `Zoom`},
    beforeEnter: rolAccess('master'),
  },

  {
    path: "/perfil",
    name: "Perfil",
    component: lazyLoad("Usuarios/Perfil"),
    meta: { layout: "autenticado", title: `Perfil`},
    beforeEnter: checkAuth,
  },

  {
    path: "/clientes",
    name: "Cientes",
    component: lazyLoad("Clientes/Clientes"),
    meta: { layout: "autenticado", title: `Clientes`},
    beforeEnter: rolAccess('master'),
  },
  {
    path: "/clientes/:id(\\d+)/edit",
    name: "Ciente Edit",
    component: lazyLoad("Clientes/Cliente-Edit"),
    meta: { layout: "autenticado", title: `Editando Cliente`},
    beforeEnter: rolAccess('master'),
  },

  {
    path: "/usuarios",
    name: "Usuarios",
    component: lazyLoad("Usuarios/Usuarios"),
    meta: { layout: "autenticado", title: `Usuarios`},
    beforeEnter: rolAccess('master', 'admin'),
  },
  {
    path: "/usuarios/:id(\\d+)",
    name: "Usuario Edit",
    component: lazyLoad("Usuarios/Usuario-Edit"),
    meta: { layout: "autenticado", title: `Editando Usuario`},
    beforeEnter: rolAccess('master', 'admin'),
  },

  {
    path: "/configuracion",
    name: "Configuracion",
    component: lazyLoad("Configuracion"),
    meta: { layout: "autenticado", title: `Configuración`},
    beforeEnter: rolAccess('master','admin'),
  },

  {
    path: "/login",
    name: "login",
    component: lazyLoad("Login"),
    meta: { layout: "default", title: `Login`},
    beforeEnter: redirectHome,
  },
  {
    path: "/sin-acceso",
    meta: { layout: "default", title: "401 - Sin Permisos" },
    component: lazyLoad("Sin-Acceso"),
  },
  {
    path: "/:pathMatch(.*)",
    meta: { layout: "default", title: "404 - Pagina no encontrada" },
    component: lazyLoad("PageNotFound"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});



router.beforeResolve((to, from, next) => {
  NProgress.start()
  document.title = `${to.meta.title}`;
  next()
});


router.afterEach((to, from) => {
  NProgress.done()
})

export default router;
