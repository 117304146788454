<template>
  <div class="table-responsive p-3" :style="styleMain">

    <div class="row align-items-center g-2 mb-3" v-if="options?.filter">

      <div class="col-lg-3">
        <div class="form-floating">
        <select id="selectFiltro" class="form-select" v-model="filterBy" @change="searchFilter = ''; (filterBy == 'all' || filterBy == 'hoy' ? filtrar() : null)">
            <option v-for="item in options.filter" :value="item.by">{{item.name}}</option>
        </select>
        <label for="selectFiltro">Aplicar Filtro</label>
        </div>
        </div>

   
        <div class="col-lg-3" v-if="!['all', 'remitente', 'destinatario', 'oficina', 'hoy', 'fecha', 'fecha-rango'].includes(filterBy)">
          <div class="form-floating">
            <input :disabled="!filterBy || filterBy == ''" 
            v-model="searchFilter" 
            @keyup.enter="filtrar"
            type="text" class="form-control" 
            id="floatingInputSearch" 
            placeholder="Escriba su búsqueda" 
            autocomplete="off"
            title="Presione ENTER o el botón correspondiente"
            >
            <label for="floatingInputSearch">Búsqueda</label>
           </div>
        </div>


        

        <div class="col-lg-3" v-if="filterBy == 'remitente'">   
          <InputCliente @selected="searchFilter = $event?.id; filtrar();" />
        </div>


        <div class="col-lg-3" v-if="filterBy == 'destinatario'">   
          <InputDestinatario @selected="searchFilter = $event?.id; filtrar();" />
        </div>


        <div class="col-lg-3" v-if="filterBy == 'oficina'">   
        <SelectOffices @selected="searchFilter = $event?.id" />
        </div>

        <div class="col-lg-3" v-if="filterBy == 'fecha' || filterBy == 'date'">   
          <SelectFecha select="empty" @selected="searchFilter = $event" :required="true"
          :min_date="new Date('2000-01-01')" />
        </div>   


        <div class="col-lg-3" v-if="filterBy == 'fecha-rango' || filterBy == 'date-range'">
          <SelectFecha select="empty" @selected="searchFilter = $event; reloadComponents()" :required="true" :titulo="'Desde'"
          :min_date="new Date('2000-01-01')" />
        </div>

        <div class="col-lg-3" v-if="filterBy == 'fecha-rango' || filterBy == 'date-range'">
          <SelectFecha select="empty" @selected="searchFilterEnd = $event" :min_date="searchFilter" :required="true" :titulo="'Hasta'" v-if="reloadComponent" />
        </div>


        <div class="col-lg-2">
          <button :disabled="filterBy !== 'all' && filterBy !== 'hoy' && !searchFilter" 
          @click="filtrar" 
          class="btn btn-primary b-radius w-100" title="Filtrado de registros segun filtro">
          Aplicar Filtro
          </button>
        </div>

    </div>
    
    <table ref="tabla" class="table table-hover nowrap w-100">
      <thead class="bg-principal-alt text-white position-sticky top-0" :style="options?.fontSizeHead ? `font-size: ${options?.fontSizeHead}` : ''">
        <tr>
          <th v-if="options?.select" class="text-white bg-primary">
            <input type="checkbox" @change="selectAll($event)">
          </th>
          <th class="text-white bg-primary" v-for='col in columns' :style="col.width ? 'width: ' + col.width : ''">
            {{ col.label }}
            <i class="fa-solid ms-2 c-pointer" :class="ascDesc == 0 ? ' fa-sort-up' : ' fa-sort-down'" :title="ascDesc == 0 ? 'Mostrar registros desde el primero' : 'Mostrar registros desde el último'" v-if="col.AscDesc"
            @click="sort = col.key; ascDesc = !ascDesc; loadDataByPagination()"></i>
          </th>
          <th class="text-white bg-primary" v-if="options?.actions">
            <i class="fa-solid fa-gear"></i>
          </th>
        </tr>
      </thead>
      <tbody class="text-center" :style="options?.fontSizeBody ? `font-size: ${options?.fontSizeBody}` : ''" v-if="!isLoading">
        <tr v-for="item in tableData">
          
        <td v-if="options?.select">
          <input type="checkbox" :data-id="item['id']" :value="item" @change="selectBy($event)" v-model="selectItems">
         </td>

          <td v-for="col in columns" :class="col.css"> 

            <div v-if="col.isStatus" :class="filterData(item[col.key], col.subkey, col.img) == 1 ? 'badge b-radius text-white bg-success' : 'badge b-radius text-white bg-danger'">
              {{  filterData(item[col.key], col.subkey, col.img) == 1 ? 'Activo' : 'Desactivado'  }}
            </div>

            <div v-else-if="col.render" v-html="col.render(item)" />
          
            <router-link title="Ver detalle" v-else-if="col.goRouter" :to="module + '/' + item['id']">
              {{ item['id'] }}
            </router-link>

            <div v-else>
              {{ filterData(item[col.key], col.subkey, col.img) }}
            </div>

            <img v-if="col.img" :src="item[col.key]" alt="" style="height: 100px; border-radius: 5px;"  />
          </td>
   
          <td v-if="options?.actions">

            <div class="d-flex justify-content-center">
            <button 
            v-if="options.view == true"
            title="Visualizar"
            class="btn btn-dark btn-view b-radius text-white">
            <i class="fa-solid fa-eye"></i>
            </button>

            <button
            v-if="options.edit == true" 
            title="Editar"
            class="btn btn-info btn-edit b-radius text-white"
            @click="editData(item.id)">
              <i class="fas fa-edit"></i>
           </button>

           <button 
           v-if="options.delete == true" 
           title="Eliminar"
           class="btn btn-danger b-radius ms-2"
           @click="toggleModalConfirm(item.id)">
           <i class="fa-regular fa-trash-can"></i>
          </button>
            </div>

          </td>
          
        </tr>
      </tbody>
    </table>

    <div v-if="isLoading" class="text-center d-flex flex-column align-items-center justify-content-center">
      <span class="spinner-border me-2 spinner-border-md mb-2" role="status"></span>
      Porfavor, espere...
    </div>

    <div v-if="!isLoading && tableData.length == 0" class="text-center d-flex flex-column align-items-center justify-content-center">
      Sin resultados para mostrar.
    </div>


    <!-- Paginación -->
    <div v-if="totalRecords" class="d-flex align-items-center justify-content-between flex-lg-row flex-column mb-3">
      <ul class="pagination">
        
         <li class="pagination-item" v-if="false">
          <span
            class="rounded-l rounded-sm border border-gray-100 px-3 py-2 cursor-not-allowed no-underline text-gray-600 h-10"
            v-if="isInFirstPage"
            >&laquo;</span
          >
          <a
            v-else
            @click.prevent="onClickFirstPage"
            class="rounded-l rounded-sm border-t border-b border-l px-3 py-2"
            href="#"
            role="button"
            rel="prev"
          >
            &laquo;
          </a>
        </li>
  
        <li class="pagination-item">
          <button
            type="button"
            @click="onClickPreviousPage"
            :disabled="isInFirstPage"
            aria-label="Go to previous page"
            class="rounded-lg border px-3 py-2 hover:bg-gray-100 no-underline mx-2 text-sm"
            :class="{'cursor-not-allowed': isInFirstPage}"
          >
           <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 -960 960 960"><path d="M559.192-258.693 337.886-480l221.306-221.307 38.922 39.73L416.537-480l181.577 181.577-38.922 39.73Z"/></svg>
          </button>
        </li>
  
        <li v-for="page in pages" class="pagination-item" :key="page.name">
          <span
            class="rounded-lg border px-3 py-2 no-underline cursor-not-allowed mx-2"
            v-if="isPageActive(page.name)"
            >{{ page.name }}
            </span>
          <a
            class="rounded-lg border border-none px-3 py-2 no-underline mx-2"
            href="#"
            v-else-if="page.name > 0"
            @click.prevent="onClickPage(page.name)"
            role="button"
            >{{ page.name }}
          </a>
          <!-- <button
            type="button"
            @click="onClickPage(page.name)"
            :disabled="page.isDisabled"
            :class="{ active: isPageActive(page.name) }" 
          >{{ page.name }}</button> -->
        </li>
  
        <li class="pagination-item">
          <button
            type="button"
            @click="onClickNextPage"
            :disabled="isInLastPage"
            aria-label="Go to next page"
            class="rounded-lg border border-gray-100 px-3 py-2 no-underline mx-2 text-sm"
            :class="{'cursor-not-allowed': isInLastPage}"
          >
                  <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 -960 960 960"><path d="M519.463-480 337.886-661.577l38.922-39.73L598.114-480 376.808-258.693l-38.922-39.73L519.463-480Z"/></svg>
          </button>
        </li>
  
        <li class="pagination-item" v-if="false">
          <!-- <button
            type="button"
            @click="onClickLastPage"
            :disabled="isInLastPage"
            aria-label="Go to last page"
          >Last</button> -->
          <a
            class="rounded-r rounded-sm border px-3 py-2 no-underline"
            href="#"
            @click.prevent="onClickLastPage"
            rel="next"
            role="button"
            v-if="hasMorePages"
            >&raquo;</a
          >
          <span
            class="rounded-r rounded-sm border border-gray-100 px-3 py-2 no-underline cursor-not-allowed"
            v-else
            >&raquo;</span
          >
        </li>
      </ul>
  
          <div class="flex items-center">
              <span class="text-sm">Mostrando {{currentPage == 1 ? 1 : perPage * (currentPage -1) + 1 }} a {{currentPage == 1 ? perPage : (perPage * currentPage) > totalRecords ? totalRecords : (perPage * currentPage)}} de {{totalRecords}} registros</span>
          
              <div class="relative ms-3">
                  <select v-model="perPage" @change="onChangeLimit" class="appearance-none bg-white border text-gray-700 py-2 px-4 pr-8 rounded-lg leading-tight focus:outline-none">
                      <option value="5">Mostrar 5</option>
                      <option value="10">Mostrar 10</option>
                      <option value="20">Mostrar 20</option>
                  </select>

                </div>
          </div>
    </div>


    <ModalConfirm v-if="tableData.length > 0" @close="toggleModalConfirm" :modalActive="modalActiveConfirm">
      <h5 class="text-uppercase">¿Estas seguro de eliminar el registro?</h5>
      <div class="buttons-modal-footer">
        <button @click="toggleModalConfirm" type="button" class="bg-dark text-white">Cancelar</button>
        <button @click="deleteData" type="button" class="btn-modal-crear bg-danger">Si, Seguro</button>
      </div>
    </ModalConfirm>

</div>
</template>
<script setup>
import axios from 'axios'
import { ref, onMounted, watch, computed, onBeforeMount } from 'vue'
import { useToast } from "vue-toastification"
import { useRouter } from 'vue-router'
import { getApiKey } from '@/helpers';
const sort = ref('')
const ascDesc = ref(0)
const filterBy = ref('')
const searchFilter = ref('')
const searchFilterEnd = ref('')
const props = defineProps({
  module: '',
  path: '',
  apikey: {type: Boolean, default: false},
  styleMain: null,
  reload: null,
  columns: Array,
  options: null,
  selectItems: Array
})
const columns = ref(props.columns)
const emits = defineEmits(['onInit', 'selectedItems', 'data', 'delete'])
const tabla = ref()
const idEle = ref(null)
const toast = useToast()
const Router = useRouter()
const isLoading = ref(true)
const modalActiveConfirm = ref(false)
const tableData = ref([])
const selectItems = ref([])
const apikey = ref(null)

////////
// Paginación
const currentPage = ref(1);
const perPage = ref(props.options?.paginationPerPage ? props.options?.paginationPerPage : 10)
const totalRecords = ref(0)
const totalPages = ref(0);
const maxVisibleButtons = ref(props.options?.paginationMaxButtons ? props.options?.paginationMaxButtons : 3);



onBeforeMount(() => {
  if(columns.value){
    columns.value = columns.value.filter(item => (item.hidden ? true : false) == false)
  }
})


watch(() => perPage.value, data => {
	if(!data){
		return totalPages.value = Math.ceil(parseFloat(totalRecords.value / perPage.value))
	}
	totalPages.value = Math.ceil(parseFloat(totalRecords.value / data))
	
})


watch(() => props.selectItems, data => {
	if(data){
		selectItems.value = props.selectItems
	}
})



const loadDataByPagination = () => {
  if(!props.options?.pagination && !props.options?.filter){
    return loadData(props.module)
  }
  let searchParams = new URLSearchParams();

  if (filterBy.value) {
    searchParams.append('search', filterBy.value);
  if (filterBy.value === 'fecha-rango') {
    searchParams.append('q', searchFilter.value + ',' + searchFilterEnd.value); // 'q' para el segundo valor del rango
  } else {
    searchParams.append('q', searchFilter.value);
  }
  }

  searchParams.append('page', currentPage.value);
  searchParams.append('limit', perPage.value);
  
  if(sort.value){
  if(ascDesc.value == 0){
    searchParams.append('sort', filterBy.value);
    searchParams.append('order', "asc");
  }
  if(ascDesc.value == 1){
    searchParams.append('sort', filterBy.value);
    searchParams.append('order', "desc");
  }
  }

  let search = props.module + (props.module.includes('?') ? '&' : '?') + searchParams.toString();
  loadData(search)
}

const startPage = computed(() => {
	if (currentPage.value === 1) {
		return 1;
	}

	if (currentPage.value === totalPages.value) {
		return totalPages.value - maxVisibleButtons.value + 1;
    //return 1
	}

	return currentPage.value - 1;
});

const endPage = computed(() => {
	return Math.min(
		startPage.value + maxVisibleButtons.value - 1,
		totalPages.value
	);
});

const pages = computed(() => {
	const range = [];

	for (let i = startPage.value; i <= endPage.value; i += 1) {
		range.push({
			name: i,
			isDisabled: i === currentPage.value,
		});
	}
	return range;
});

const isInFirstPage = computed(() => {
	return currentPage.value === 1;
});

const isInLastPage = computed(() => {
	return currentPage.value === totalPages.value;
});

const onClickFirstPage = () => {
  loadDataByPagination()
};

const onClickPreviousPage = () => {
    currentPage.value --
    loadDataByPagination()
};

const onClickPage = (page) => {
  currentPage.value = page;
  loadDataByPagination()
};

const onClickNextPage = () => {
  currentPage.value ++
  loadDataByPagination()
};

const onClickLastPage = () => {
	loadDataByPagination()
};

const onChangeLimit = () => {
  perPage.value = parseInt(perPage.value)
	if(perPage.value > currentPage.value || currentPage.value == perPage.value){
	currentPage.value = 1
	}
  loadDataByPagination()
};

const isPageActive = (page) => {
	return currentPage.value === page;
};
////////


const reloadComponent = ref(true)
const reloadComponents = () => {
    reloadComponent.value = false
    setTimeout(() => {
        reloadComponent.value = true
    }, 10)
}


const selectAll = (event) => {
  selectItems.value = []
  if(event.target.checked){
  for(let ele of tableData.value){
    selectItems.value.push(ele)
  }
  }
  emits('selectedItems', selectItems.value)
}


const selectBy = (event) => {
  let itemId = event.target.dataset.id
  if(event.target.checked){
    let filter = tableData.value.filter(item => item.id == itemId)[0]
    if(filter){
      if(!selectItems.value.filter(item => item.id == filter.id)[0]){
      selectItems.value.push(filter)
      }
    }
  }else{
    let index = selectItems.value.findIndex(item => item.id == itemId)
    if(index !== -1){
      selectItems.value.splice(index, 1)
    }
  }
  emits('selectedItems', selectItems.value)
}


const toggleModalConfirm = (id) => {
  idEle.value = id
  modalActiveConfirm.value = !modalActiveConfirm.value;
}


const filterData = (data, subkey, isImg) => {
  if(isImg){
    return ''
  }

  if(!subkey){
    return data
  }
  if(data && data[subkey]){
    return data[subkey]
  }else{
    return ''
  }
}


const loadData = async (module) => {
  isLoading.value = true
  try {
    let response
    if(props.apikey){
      apikey.value = apikey.value ? apikey.value : await getApiKey()
      const headers = {
        "api-key": apikey.value
      }
      response = (await axios.get(module ? module : props.module, {headers})).data
    }else{
      response = (await axios.get(module ? module : props.module)).data
    }


    isLoading.value = false
    emits('onInit', tabla.value)
    if(response.toString().includes('DOCTYPE html')){
      tableData.value = []
      emits('data', tableData.value)
      return 
    }
    if(response.pagination && response.data){
      currentPage.value = response.pagination.currentPage
      totalRecords.value = response.pagination.totalRecords
      totalPages.value = response.pagination.totalPages
      tableData.value = response.data
      emits('data', tableData.value)
      return 
    }

    if(!response.status && response.length == 0){
      tableData.value = []
      emits('data', tableData.value)
      return
    }

    if(response.length > 0){
      tableData.value = response
    }else{
      tableData.value = []
    }

    emits('data', tableData.value)
  } catch (error) {
    isLoading.value = false
    tableData.value = []
    emits('data', tableData.value)
    toast.error("No fue posible cargar la data, reporte con soporte: " + error)
  }
}

const editData = async (id) => {
    const pathModulo = props.path ? props.path + '/' + id : props.module + '/' + id + '/edit'
    Router.push(pathModulo)
}


const deleteData = async () => {
  try {
  const pathModulo = props.path ? props.path : props.module 
  isLoading.value = true
  const response = (await axios.delete(pathModulo + `/${idEle.value}`)).data
  isLoading.value = false
  emits('delete')
  if(response.status){
        let index = tableData.value.findIndex(item => item.id == idEle.value)
        if(index !== -1){
          tableData.value.splice(index, 1)
        }
        toast.success(response.mensaje)
      } else {
        toast.error(response.mensaje)
      }
      toggleModalConfirm()
   } catch (error) {
      isLoading.value = false
      toast.error('Error eliminando registro')
    }
}

watch(() => props.reload, data => {
  if(data){
    loadDataByPagination()
  }
})



watch(() => props.module, data => {
  if(data){
    loadDataByPagination()
  }
})



const filtrar = () => {
  if(!searchFilter.value && !filterBy.value){
    return
  }
  loadDataByPagination()
  if(filterBy.value.includes('all', 'hoy')){
    filterBy.value = ""
  }
}

onMounted(async () => {
  loadDataByPagination()
})
</script>

<style scoped>
.pagination {
    list-style-type: none;
    display: flex;
    align-items: center;
}
  
.pagination-item {
    flex-direction: row;
}

.bg-primary{
  background-color: var(--theme-primary) !important;
}

.bg-secondary{
  background-color: var(--theme-secondary) !important;
}

a{
  color: var(--theme-primary) !important;
  transition: 0.3s all;
}

a:hover{
  color: var(--theme-secondary) !important;
}
</style>
