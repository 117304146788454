<template>
  <div class="form-floating">
  <v-select class="form-control" 
  :options="paises" 
  :get-option-label="(option) => option.pais" 
  :reduce="paises => paises" 
  @option:selected="setPais"
  @change="setPais" v-model="pais">></v-select>
  <label for="floatingSelect">{{titulo ? titulo : 'País'}} <span class="text-danger fw-bold">[*]</span></label>
  </div>
</template>

<script setup>
const props = defineProps({
  titulo: String,
  pais_select: [String, null, Object],
  reload: null
})

import {ref, onMounted, watch} from 'vue'
import { getPaises } from '@/helpers';
const paises = ref([])
const pais = ref(props.pais_select)
const emit = defineEmits(['onSetPais'])
const latinoAmerica = [
  "Argentina", "Bolivia", "Brasil", "Chile", "Colombia", "Costa Rica", 
  "Cuba", "Ecuador", "El Salvador", "Guatemala", "Honduras", "Mexico", 
  "Nicaragua", "Panama", "Paraguay", "Peru", "Puerto Rico", "Republica Dominicana", 
  "Uruguay", "Venezuela", "United States"
];


const setPais = () => {
emit('onSetPais', pais.value)
}

onMounted(async () => {
  let response = await getPaises()
  response = response.filter(item => latinoAmerica.includes(item.pais))
  paises.value = response
  if(props.pais_select){
    pais.value = paises.value.filter(item => item.pais.toLowerCase() == props.pais_select.toLowerCase())[0]
  }
  setPais()
})


watch(() => props.reload, data => {
  if(data){
    setPais()
  }
})
</script>