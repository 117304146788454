import { defineStore } from 'pinia'

export const useUpdateStore = defineStore('updateStore', {
  state: () => ({
    showUpdateModal: false,
    showDownloadingModal: false,
    registration: null,
  }),
  actions: {
    setDownloading() {
      this.showDownloadingModal = true;
    },
    setUpdateReady(registration) {
      this.showDownloadingModal = false;
      this.showUpdateModal = true;
      this.registration = registration;
    },
    confirmUpdate() {
      if(this.registration && this.registration.waiting){
        this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        caches.keys().then(names => {
          for (let name of names) caches.delete(name);
        });
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    }
  }
})
