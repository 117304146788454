<template>
  <footer class="app-footer" v-if="$auth.autenticado">
    <hr />
    <div class="d-flex flex-lg-row flex-column justify-content-between">
      <p class="mb-0 text-uppercase color-principal">© copyright 2025</p>
      <div class="autor-link">Derechos reservados <b>SUR AMERICA CARGO</b> </div>
    </div>
  </footer>
</template>

<style scoped>
.app-footer {
  transition: 0.3s ease-in-out;
  position: absolute;
  bottom: -5rem;
  width: 100%;
  padding: 0 0 1rem 0;
  color: var(--primary)
}

.autor-link{
  color: var(--primary)!important;
  transition: 0.3s all;
}


.autor-link:hover{
  color: var(--secondary)!important;
}

@media (max-width: 991px) {
  .app-footer {
    padding: 1rem !important;
  }
}
</style>

